import { Box } from '@mui/material'
import TextInput from '../../Components/TextInput'
import { Button } from '@lmig/lmds-react-button'
import React, {useState} from 'react'
import { GridCol, GridRow } from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Heading } from '@lmig/lmds-react-typography'
import { FieldGroup } from '@lmig/lmds-react-field-group'
import { Select, SelectOption } from '@lmig/lmds-react-select'
import { STATE_LIST , ERROR_LIST, LIB_POL_PROG_CODES } from '../../constants'

// First page in the file upload process. 
// User fills out personal information/details.
const PolicyDetails = (props) => {
  const padding = useCheckIsMobile() ? '10px 0px 0px 0px' : '20px'
  const headingMargin = useCheckIsMobile() ? '0px' : '17px'
  const [isFocus, setisFocus] = useState(false)
  const [lastGoodPN, setlastGoodPN] = useState('')
  const [state, setState] = useState(props.values.policyDetails.state.value)
  const [progCode, setProgCode] = useState(props.values.policyDetails.programCode.value)

  // Resets fields if policy type is changed (H or A in the beginning)
  const resetValues  = (e) => {
    const val = props.values.policyDetails.policyNumber.value
    if (isFocus === false) {
      if (val.length === 14 && (val.startsWith('H') || val.startsWith('A'))) {
        setlastGoodPN(val)
        setisFocus(true)
      }
    } else if (isFocus === true) {
      if (val.length === 14 && (val.startsWith('H') || val.startsWith('A'))) {
        if ((lastGoodPN.startsWith('H') && val.startsWith('A')) || (lastGoodPN.startsWith('A') && val.startsWith('H'))) {
          setlastGoodPN(val)
          props.handleSetValuesDetails('resetContextValues', e)
          setState('')
          setProgCode('')
          props.values.policyDetails.policyNumber.value = val
          setisFocus(false)
        }
      }
    }
  }

  // Sets the program code field
  const handleProgramCodeDropdown = (e) => {
    setProgCode(e.target.value)
    props.values.policyDetails.programCode.value = e.target.value;
    props.handleSetValuesDetails('policy', e)
  }

  // Sets the state field
  const handleStateDropdown = (e) => {
    setState(e.target.value)
    props.values.policyDetails.state.value = e.target.value;
    props.handleSetValuesDetails('policy', e)
  }

  // Renders text input fields for user policy number, full name, and email
  // Once mandatory fields are filled out, user can navigate to the File Selection page by clicking the Continue button
  return (
    <React.Fragment >
      <GridRow data-testid='details'>
        <GridCol>
          <Heading align='left'
            style={{
              fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginLeft: headingMargin, fontStyle: 'normal',
              fontWeight: '400',
              height: '26px',
              fontSize: '16px',
              lineHeight: '26px'
            }} className='subheading-largescreen'>
                Please enter your information to help us manage your files correctly.
          </Heading>
          <Box style={{ padding, fontSize: 'small' }} className='textinput_container' data-testid='box'>
            <TextInput maxLength={14} data-testid='policyNumber' valueKey='policyNumber' reason='policy' detail={props.values.policyDetails} onBlur = {resetValues()}/>
            






            <FieldGroup
              id="fieldgroup--error"
              isFull
              disableMargins='all'
              messages={[
                {
                  text: `${ERROR_LIST.state}`,
                  type: props.values.policyDetails.state.error ? 'error' : null
                }
              ]}
            >
            
              <Select
                highlightType={props.values.policyDetails.state.error ? 'error' : null}
                id="state"
                name="state"
                labelVisual="State"
                value={state}
                isRequired
                onChange={(e) => handleStateDropdown(e)}
                onBlur={(e) => props.handleSetValuesDetails('policy', e)}
              >
                <SelectOption key="none" value="">--Select an option--
                </SelectOption>
                {STATE_LIST.map((item) => (
                  <SelectOption key={item.code} value={item.code}>
                    {item.code}
                  </SelectOption>
                ))}       

              </Select>
            </FieldGroup>

            <TextInput maxLength={200} data-testid='contactName' valueKey='contactName' reason='policy' detail={props.values.policyDetails} />
            <TextInput maxLength={200} data-testid='contactEmail' valueKey='contactEmail' reason='policy' detail={props.values.policyDetails}/>
            
            <Heading align='left'
              style={{
                fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginBottom: '3em', marginLeft: '-5px', fontStyle: 'normal',
                fontWeight: '400',
                height: '26px',
                fontSize: '16px',
                lineHeight: '26px'
              }} className='subheading-largescreen'>
              Please leave the following dropdown blank if you have not been provided a specific program name/code.

            </Heading>

            <FieldGroup
              id="fieldgroup--error"
              isFull
              disableMargins='all'
              // messages={[
              //   {
              //     text: `${ERROR_LIST.programCode}`,
              //     type: props.values.policyDetails.programCode.error ? 'error' : null
              //   }
              // ]}
            >
              <Select
                // highlightType={props.values.policyDetails.programCode.error ? 'error' : null}
                id="programCode"
                name="programCode"
                labelVisual="Program Code"
                value={progCode}
                // isRequired
                onChange={(e) => handleProgramCodeDropdown(e)}
                onBlur={(e) => props.handleSetValuesDetails('policy', e)}
              >
                <SelectOption key="none" value="">--Select an option--</SelectOption>
                {LIB_POL_PROG_CODES.map((item) => (
                  (<SelectOption key={item.toString()} value={item.toString()}>{item.toString()}</SelectOption>)
                ))}
              </Select>
            </FieldGroup>



            <Button
              disabled={!props.values.enableContinueInfoPage}
              id='continue'
              data-testid= 'continue'
              isLargeOnMobile={true}
              size="medium"
              type='submit'
              variant='primary'
              dynamicWidth={true}
              style={{ width: '-webkit-fill-available', marginTop: '10px' }}
              onClick={(e) => props.handleSetValuesDetails('activeStep', e) }
            >
              Continue
            </Button>
          </Box>
        </GridCol>
      </GridRow>
    </React.Fragment>
  )
}

export default PolicyDetails
