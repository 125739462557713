import React from 'react'
import { FormProvider } from './FormContext/FormContext'
import { Content, Header, LogoCobrand } from '@lmig/lmds-react'
import Controller from './Components/Controller'
import LibertyFooter from './Components/LibertyFooter'
import SplashPage from './Pages/SplashPage/SplashPage'
import 'typeface-roboto'

const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE

const setAppMode = () => {
  if (maintenanceMode.toLowerCase() === 'true') {
    return <SplashPage />
  } else {
    return <Controller />
  }
}

const App = () => (
  <>
    <Header>
      <LogoCobrand />
    </Header>
    <Content >
      <FormProvider>{setAppMode()}</FormProvider>
    </Content>
    <LibertyFooter />
  </>
)

export default App
