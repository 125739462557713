import React from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import '../Pages/FileSelection/FileDataView.css'
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile'
import { CHOOSE_FILE_TEXT } from '../constants'
import { Heading } from '@lmig/lmds-react'
import { IconAdd } from '@lmig/lmds-react-icons'

const baseStyle = {
  marginTop: '15px',
  width: '160xpx',
  marginLeft: '25px'
}

// Component for choosing another file when initials have been uploaded, located above 'review' button.
const ChooseAnotherFileText = ({ onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    open
  } = useDropzone({ onDrop, noClick: true, noDrag: true })
  baseStyle.marginLeft = useCheckIsMobile() ? '0' : '25px'

  return (
    <Box {...getRootProps(baseStyle)}>
      <input {...getInputProps()}/>
      <IconAdd size="16" color="teal" style={{ cursor: 'pointer', display: 'inline-block', marginBottom: '3px' }} onClick={open}/>
      <Heading type='h1' align='left' className='fileview-choosefile' onClick={open}>
        {CHOOSE_FILE_TEXT}
      </Heading>
    </Box>
  )
}

ChooseAnotherFileText.propTypes = {
  onDrop: PropTypes.func
}

export default ChooseAnotherFileText
