import React from 'react'
import { IconSuccessAlert } from '@lmig/lmds-react-icons'
import { Button, GridCol } from '@lmig/lmds-react'
import { Heading } from '@lmig/lmds-react-typography'
import { Box } from '@mui/material'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'

// Renders Success Page when file upload is complete and successful

// refreshes page to start a new file upload session
const backToStart = (e) => {
  window.location.reload(false)
}

function SuccessPage() {
  const buttonWidth = useCheckIsMobile() ? '95%' : '373 px'

  return (
    <Box base={12} style={{ width: '100%' }} data-testid='success'>
      <GridCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <IconSuccessAlert color="positive" size='48' ></IconSuccessAlert>
        <Heading type='h3' style={{ color: '#008040', textAlign: 'center' }}>Success! Your files have <br></br> been uploaded.</Heading>
        {useCheckIsMobile() ? <hr style={{ marginTop: '22px', marginLeft: '21px', marginRight: '21px ', width: '99%' }} /> : <hr style={{ marginTop: '22px', marginLeft: '21px', marginRight: '21px ', width: '373px' }} />}
        <p style={{ textAlign: 'center' }}>It may take up to 1 business day for us to finalize this process.<br></br> You may now close your window
          OR</p>
        <Button id='successButton' onClick={backToStart} style={{ width: buttonWidth }} data-testid='upload-more'>Upload more files</Button>
      </GridCol>
    </Box>
  )
}

export default SuccessPage
