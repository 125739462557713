import axios from 'axios'
import getEnv from './getEnv'
import { getDoctypeInternalClaim } from './doctypeMappingClaim'
import { getDoctypeInternalQuote } from './doctypeMappingQuote'
import { getDoctypeInternalPolicy } from './doctypeMappingPolicy'
import { getDoctypeInternalSafecoPolicy } from './doctypeMappingSafecoPolicy'
import { getDoctypeInternalBLPolicy } from './doctypeMappingBLPolicy'
import { getEmailForPolicyDocLevel, getEmailForPolicyTransLevel } from './utils/getEmailForPolicy'
import { getEmailForSafecoDocLevel, getEmailForSafecoTransLevel } from './utils/getEmailForSafeco'
import getEmailForQuote from './utils/getEmailForQuote'
import { policyDetails } from './FormContext/PolicyContext'

// API called when "Upload files" is clicked on review page and submits a post request with given data
export async function uploadServiceApi(formValues) {
  const uploadsContext = formValues.uploads;
  const claimContext = formValues.claimDetails;
  const quoteContext = formValues.quoteDetails;
  const policyContext = formValues.policyDetails;
  const blpolicyContext = formValues.blPolicyDetails;
  const safecoPolicyContext = formValues.safecoPolicyDetails;


  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData'
    }
  }
  const data = new FormData()
  data.append('transactionId', formValues.transactionId);
  data.append('uploadReason', formValues.uploadReason);

  // Claims
  if (formValues.uploadReason === 'personal-claim') {
    const claimContextLength = `${claimContext.claimNumber.value}-01`;
    Object.keys(claimContext).forEach((key) => {
      if (key === 'claimNumber' && claimContext[key].value.length === 9) {
        data.append(key, claimContextLength)
      } else {
        data.append(key, claimContext[key].value)
      }
    })
    for (const file of uploadsContext.files) {
      const category = file.rawFile.category ? file.rawFile.category : '';
      const subcategory = file.rawFile.subcategory ? file.rawFile.subcategory : '';
      const [categoryInternal, subcategoryInternal] = getDoctypeInternalClaim(category, subcategory);
      const fileBlob = dataURItoBlob(file.rawFile);

      data.append('fileBlob', fileBlob, file.rawFile.name);
      data.append(`${file.rawFile.name}-category`, categoryInternal);
      data.append(`${file.rawFile.name}-subcategory`, subcategoryInternal);
      data.append(`${file.rawFile.name}-comment`, file.rawFile.comment);
    }
  }

  // BL Policy
  if (formValues.uploadReason === 'bl-policy') {
    data.append('accountNumber', blpolicyContext.blPolicyNumber.value);
    data.append('policyExpirationYear', blpolicyContext.blPolicyExpYear.value);

    const uploaderFname = blpolicyContext.contactFirst.value;
    const uploaderLname = blpolicyContext.contactLast.value;
    const uploaderName = `${uploaderFname} ${uploaderLname}`;
    data.append('uploadedBy', uploaderName);
    data.append('contactEmail', blpolicyContext.contactEmail.value);

    for (const file of uploadsContext.files) {
      const category = file.rawFile.category ? file.rawFile.category : '';
      const subcategory = file.rawFile.subcategory ? file.rawFile.subcategory : '';
      const mappingObj = getDoctypeInternalBLPolicy(category, subcategory);
      const fileBlob = dataURItoBlob(file.rawFile);

      data.append('fileBlob', fileBlob, file.rawFile.name);
      data.append(`${file.rawFile.name}-docType`, mappingObj.docType);
      data.append(`${file.rawFile.name}-docDescription`, mappingObj.docDescription);
      data.append(`${file.rawFile.name}-retentionClass`, mappingObj.retentionClass);
      data.append(`${file.rawFile.name}-documentClassification`, mappingObj.documentClassification);
      data.append(`${file.rawFile.name}-notification`, mappingObj.notification);
      data.append(`${file.rawFile.name}-comment`, file.rawFile.comment);
      data.append(`${file.rawFile.name}-dropFlag`, mappingObj.dropFlag);
    }
  }

  // Quote
  if (formValues.uploadReason === 'personal-quote') {
    data.append('lineOfBusiness', formValues.quoteDetails.lineOfBusiness.value);
    data.append('stateCode', `${formValues.quoteDetails.state.value ? formValues.quoteDetails.state.value : ''}`);
    data.append('contactEmail', formValues.quoteDetails.contactEmail.value);
    data.append('programCode', formValues.quoteDetails.programCode.value);

    const phone = formValues.quoteDetails.phoneNumber.value;
    data.append('phoneNumber', phone.replace(/[()\s-]/g, ''));

    const uploaderFname = formValues.quoteDetails.contactFirst.value;
    const uploaderLname = formValues.quoteDetails.contactLast.value;
    const uploaderName = `${uploaderFname} ${uploaderLname}`;
    data.append('uploadedBy', uploaderName);

    if (formValues.quoteDetails.isCustomer?.value === 'yes') {
      data.append('customerFirst', formValues.quoteDetails.contactFirst.value);
      data.append('customerLast', formValues.quoteDetails.contactLast.value);
      data.append('emailSubject', `SecureUp: ${formValues.quoteDetails.programCode.value} ${formValues.quoteDetails.contactFirst.value} ${formValues.quoteDetails.contactLast.value} ${formValues.quoteDetails.lineOfBusiness.value}`);
    }
    if (formValues.quoteDetails.isCustomer?.value === 'no') {
      data.append('customerFirst', formValues.quoteDetails.customerFirst.value);
      data.append('customerLast', formValues.quoteDetails.customerLast.value);
      data.append('emailSubject', `SecureUp: ${formValues.quoteDetails.programCode.value} ${formValues.quoteDetails.customerFirst.value} ${formValues.quoteDetails.customerLast.value} ${formValues.quoteDetails.lineOfBusiness.value}`);
      data.append('customerEmail', formValues.quoteDetails.customerEmail.value);
    }

    // const emailList = [];
    let prefNotification = 'No';
    let prefManualIndexing = 'No';
    for (const file of uploadsContext.files) {
      const category = file.rawFile.category ? file.rawFile.category : '';
      const subcategory = file.rawFile.subcategory ? file.rawFile.subcategory : '';
      const mappingObj = getDoctypeInternalQuote(category, subcategory);
      const fileBlob = dataURItoBlob(file.rawFile);

      // const emailAddress = getEmailForQuote(quoteContext.programCode.value); 
      // emailList.push(emailAddress);
      if (mappingObj.manualIndexing === 'Yes') prefManualIndexing = 'Yes';
      if (mappingObj.notification === 'Yes') prefNotification = 'Yes';

      data.append('fileBlob', fileBlob, file.rawFile.name);
      data.append(`${file.rawFile.name}-docType`, mappingObj.docDescription);
      data.append(`${file.rawFile.name}-documentTypeCode`, mappingObj.pmcode);
      data.append(`${file.rawFile.name}-retentionClass`, mappingObj.retentionClass);
      data.append(`${file.rawFile.name}-comment`, file.rawFile.comment);
    }

    // data.append('workflowMailbox', extractPreferredEmail(emailList));
    /* updated logic to fetch email address based on program code */
    data.append('workflowMailbox', getEmailForQuote(quoteContext.programCode.value));
    data.append('manualIndexing', prefManualIndexing);
    data.append('notification', prefNotification);
  }

  // Policy
  if (formValues.uploadReason === 'personal-policy') {
    Object.keys(policyContext).forEach((key) => {
      data.append(key, policyContext[key].value)
    })

    let lob = '';
    if (policyContext.policyNumber.value.startsWith('H')) lob = 'homeowner';
    if (policyContext.policyNumber.value.startsWith('A')) lob = 'auto';

    if (policyContext.policyNumber.value) {
      data.append('programCode', policyContext.programCode.value)
    }

    // Liberty email policy indicator for Pipeline and the email subject to pipeline is also updated
    const libPolicyEmailInd = policyContext.programCode.value ? 'transaction' : 'document'
    data.append('libPolicyEmailInd', libPolicyEmailInd)
    if (policyContext.programCode.value) {
      data.append('emailSubject', `SecureUp: ${policyContext.programCode.value} ${policyContext.policyNumber.value}`);
    }
    if (!policyContext.programCode.value) {
      data.append('emailSubject', `SecureUp: ${policyContext.policyNumber.value}`);
    }

    if (libPolicyEmailInd === 'transaction') {
      data.append('workflowMailbox', getEmailForPolicyTransLevel(policyContext.programCode.value));
    }



    for (const file of uploadsContext.files) {
      const category = file.rawFile.category ? file.rawFile.category : '';
      const subcategory = file.rawFile.subcategory ? file.rawFile.subcategory : '';
      const mappingObj = getDoctypeInternalPolicy(category, subcategory);
      const fileBlob = dataURItoBlob(file.rawFile);

      const emailAddress = getEmailForPolicyDocLevel(lob, mappingObj.docDescription, policyContext.state.value);

      data.append('fileBlob', fileBlob, file.rawFile.name);
      data.append(`${file.rawFile.name}-docType`, mappingObj.pmcode);
      data.append(`${file.rawFile.name}-documentTypeDesc`, mappingObj.docDescription);
      data.append(`${file.rawFile.name}-retentionClass`, mappingObj.retentionClass);
      data.append(`${file.rawFile.name}-manualIndexing`, mappingObj.manualIndexing);
      data.append(`${file.rawFile.name}-notification`, mappingObj.notification);
      data.append(`${file.rawFile.name}-comment`, file.rawFile.comment);
      if (libPolicyEmailInd === 'document') {
        data.append(`${file.rawFile.name}-workflowMailbox`, emailAddress);
      }
    }
  }

  // safeco-policy
  if (formValues.uploadReason === 'safeco-policy') {
    const policyLength = formValues.safecoPolicyDetails.safecoPolicyNumber.value.length;
    let lob = '';

    if (policyLength === 8) lob = 'auto';
    if (policyLength === 9) lob = 'homeowner';

    if (safecoPolicyContext.safecoPolicyNumber.value) {
      data.append('programCode', safecoPolicyContext.programCode.value)
    }

    // Safeco email policy indicator for Pipeline and the email subject to pipeline is also updated
    const safPolicyEmailInd = safecoPolicyContext.programCode.value ? 'transaction' : 'document'
    data.append('safPolicyEmailInd', safPolicyEmailInd)
    if (safecoPolicyContext.programCode.value) {
      data.append('emailSubject', `SecureUp: ${safecoPolicyContext.programCode.value} ${safecoPolicyContext.safecoPolicyNumber.value}`);
    }
    if (!safecoPolicyContext.programCode.value) {
      data.append('emailSubject', `SecureUp: ${safecoPolicyContext.safecoPolicyNumber.value}`);
    }

    if (safPolicyEmailInd === 'transaction') {
      data.append('workflowMailbox', '');
      data.append('programEmailAddress', getEmailForSafecoTransLevel(safecoPolicyContext.programCode.value));
    }

    data.append('safecoPolicyNumber', formValues.safecoPolicyDetails.safecoPolicyNumber.value);
    data.append('lineOfBusiness', lob);
    data.append('stateCode', `${formValues.safecoPolicyDetails.state.value ? formValues.safecoPolicyDetails.state.value : ''}`);
    data.append('contactEmail', formValues.safecoPolicyDetails.contactEmail.value);

    const uploaderFname = formValues.safecoPolicyDetails.contactFirst.value;
    const uploaderLname = formValues.safecoPolicyDetails.contactLast.value;
    const uploaderName = `${uploaderFname} ${uploaderLname}`;
    data.append('uploadedBy', uploaderName);

    if (formValues.safecoPolicyDetails.isCustomer?.value === 'yes') {
      data.append('customerFirst', formValues.safecoPolicyDetails.contactFirst.value);
      data.append('customerLast', formValues.safecoPolicyDetails.contactLast.value)
    }
    if (formValues.safecoPolicyDetails.isCustomer?.value === 'no') {
      data.append('customerFirst', formValues.safecoPolicyDetails.customerFirst.value);
      data.append('customerLast', formValues.safecoPolicyDetails.customerLast.value)
    }

    for (const file of uploadsContext.files) {
      const category = file.rawFile.category ? file.rawFile.category : '';
      const subcategory = file.rawFile.subcategory ? file.rawFile.subcategory : '';
      const mappingObj = getDoctypeInternalSafecoPolicy(category, subcategory, lob);
      const fileBlob = dataURItoBlob(file.rawFile);

      data.append('fileBlob', fileBlob, file.rawFile.name);
      data.append(`${file.rawFile.name}-docDesc`, mappingObj.docDescription);
      data.append(`${file.rawFile.name}-docName`, mappingObj.docName);
      data.append(`${file.rawFile.name}-retentionClass`, mappingObj.retentionClass);
      data.append(`${file.rawFile.name}-manualIndexing`, mappingObj.manualIndexing);
      data.append(`${file.rawFile.name}-notification`, mappingObj.notification);

      if (safPolicyEmailInd === 'document') {
        if (getEnv('ENV') === 'production') {
          data.append(`${file.rawFile.name}-workflowMailbox`, mappingObj.workflowMailbox);
        } else {
          data.append(`${file.rawFile.name}-workflowMailbox`, 'docsolpipelinecrew@libertymutual.com');
          data.append(`${file.rawFile.name}-doctypeMailboxAddress`, `***NonPROD-${mappingObj.workflowMailbox}-NonPROD***`);
        }
      }
      
      data.append(`${file.rawFile.name}-comment`, file.rawFile.comment);
    }
  }

  const response = await axios.post(`${getEnv('API')}/upload/metadata`, data)
  return response.status
}

const dataURItoBlob = (rawFile) => {
  const binary = atob(rawFile.src.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: rawFile.type })
}

const extractPreferredEmail = (emailList) => {
  // production
  if (getEnv('ENV') === 'production') {
    const quoteExcpEmailList = ['midocs@libertymutual.com', 'sapipPLDRDC@libertymutual.com', 'InsVerification@Libertymutual.com', 'approvaldocuments@libertymutual.com'];
    const quoteDefEmailList = ['lmdocuments@libertymutual.com']

    const defaultEmailUnqListUser = [];
    const excpEmailUnqListUser = [];

    // creating separate unique email list for default and exception emails
    emailList.forEach((email) => {
      if (quoteExcpEmailList.includes(email)) {
        if (!excpEmailUnqListUser.includes(email)) excpEmailUnqListUser.push(email);
      } else {
        if (!defaultEmailUnqListUser.includes(email)) defaultEmailUnqListUser.push(email);
      }
    })

    let prefEmail = quoteDefEmailList[0];
    switch (true) {
    case excpEmailUnqListUser.length === 1:
      prefEmail = excpEmailUnqListUser[0];
      break;
    case defaultEmailUnqListUser.length === 1 && excpEmailUnqListUser.length === 0:
      prefEmail = defaultEmailUnqListUser[0];
      break;
    case excpEmailUnqListUser.length > 1:
      prefEmail = quoteDefEmailList[0];
      break;
    default:
      prefEmail = quoteDefEmailList[0];
    }
    return prefEmail;
  } else {
    // non-prod
    const quoteExcpEmailListNP = ['sapisPLDRDC@libertymutual.com'];
    const quoteDefEmailListNP = ['docsolpipelinecrew@libertymutual.com'];

    const defaultEmailUnqListUserNP = [];
    const excpEmailUnqListUserNP = [];

    // creating separate unique email list for default and exception emails
    emailList.forEach((email) => {
      if (quoteExcpEmailListNP.includes(email)) {
        if (!excpEmailUnqListUserNP.includes(email)) excpEmailUnqListUserNP.push(email);
      } else {
        if (!defaultEmailUnqListUserNP.includes(email)) defaultEmailUnqListUserNP.push(email);
      }
    })

    let prefEmailNP = quoteDefEmailListNP[0];
    switch (true) {
    case excpEmailUnqListUserNP.length === 1:
      prefEmailNP = excpEmailUnqListUserNP[0];
      break;
    case defaultEmailUnqListUserNP.length === 1 && excpEmailUnqListUserNP.length === 0:
      prefEmailNP = defaultEmailUnqListUserNP[0];
      break;
    case excpEmailUnqListUserNP.length > 1:
      prefEmailNP = quoteDefEmailListNP[0];
      break;
    default:
      prefEmailNP = quoteDefEmailListNP[0];
    }
    return prefEmailNP;
  }
}

/* istanbul ignore next */
export const uploadtestapi = () => new Promise((resolve, reject) => {
  axios
    .get(`${getEnv('API')}/upload/file`)
    .then((response) => (response.status === 200 ? resolve() : reject()))
    .catch((error) => reject(error))
})
