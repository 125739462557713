import { isMandatory, SAF_POL_PROG_CODES } from '../constants'

const initialSafecoPolicyDetails = {
  safecoPolicyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidSafecoPolicyNumber(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => true
  },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  isCustomer: {
    value: '',
    error: false,
    valid: (value) => validCustomerSelect(value)
  },
  customerFirst: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerFirst(value)
  },
  customerLast: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerLast(value)
  },
}

const safecoPolicyDetails = {
  safecoPolicyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidSafecoPolicyNumber(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => true
  },
  // lineOfBusiness: {
  //   value: '',
  //   error: false,
  //   valid: (value) => isMandatorySelect(value)
  // },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  isCustomer: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  customerFirst: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerFirst(value)
  },
  customerLast: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerLast(value)
  },
}

// Given a policy number, checks if it starts with 'H' or 'A' and length 14
// Returns true false otherwise
const isValidSafecoPolicyNumber = (val) => {
  const reg = /^(\d{1,9})$/
  // ABCDEFHJKLMPRSWXYZ
  if (val.length === 8 && 'ABCDEFHJKLMPRSWXYZ'.includes(val.toUpperCase().charAt(0)) && reg.test(val.substring(1))) { // AUTO
    return true
  } else if (val.length === 9 && val.toUpperCase().charAt(0) === 'O' && 'ABCDEFHJKLMPRSWXYZ'.includes(val.toUpperCase().charAt(1)) && reg.test(val.substring(2))) {
    return true
  }
  return false
}
  
// Validates user e-mail is in the correct format
// Returns true if in correct format, false otherwise
const isValidEmail = (val) => {
  const re =
    /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
  return re.test(val)
}

// Validates the select is not blank, returns false if blank and true if not
const isMandatorySelect = (val) => val !== ''

// Validates users response to 'Are you the customer?'
// User response will be yes or no
const validCustomerSelect = (val) =>  val === 'yes' || val === 'no'

// Validates customer first name
// Returns true if user user is not customer and the value is not empty
const isValidCustomerFirst = (values) => {
  if (values.safecoPolicyDetails?.isCustomer?.value === 'no') {
    if (values.safecoPolicyDetails.customerFirst.value === '') {
      return false
    }
  } 
  return true
}

// Validates customer last name
// Returns true if user user is not customer and the value is not empty
const isValidCustomerLast = (values) => {
  if (values.safecoPolicyDetails?.isCustomer?.value === 'no') {
    if (values.safecoPolicyDetails.customerLast.value === '') {
      return false
    }
  }
  return true
}

// Returns a boolean and checks if the details are valid and changes error to false
// Controls whether or not user is able to move to the next page
const validateSafecoPolicyDetails = (values, setValues) => { 
  let error = false
  Object.keys(values.safecoPolicyDetails).forEach((key) => {
    const field = values.safecoPolicyDetails[key]
    if (field.valid) {
      values.safecoPolicyDetails[key].error = !field.valid(field.value)
      setValues((prevValues) => ({
        ...prevValues
      }))
      if (values.safecoPolicyDetails[key].error) {
        error = values.safecoPolicyDetails[key].error
      }
    }
  })
  return !error
} 

// Adding URL paramter code below:
// Checking URL parameters, and creating variables for the values from the InformationScreen.jsx values. Then creating variables from the values after parsing through the URL. We then compare the values for PolicyNumber, state, contactFirst, contactLast, contactEmail, isCustomer, customerFirst, and customerLast with safecoPolicyDetails from InformationScreen.jsx
const checkUrlParams = (event, values, setValues) => {
  const queryString = window.location.search;
  const policyNumber = values.safecoPolicyDetails.safecoPolicyNumber.value;
  const programCode = values.safecoPolicyDetails.programCode.value;
  const state = values.safecoPolicyDetails.state.value;
  const contactFirst = values.safecoPolicyDetails.contactFirst.value;
  const contactLast = values.safecoPolicyDetails.contactLast.value;
  const contactEmail = values.safecoPolicyDetails.contactEmail.value;
  const isCustomer = values.safecoPolicyDetails.isCustomer.value;
  // const customerFirst = values.safecoPolicyDetails.customerFirst.value;
  // const customerLast = values.safecoPolicyDetails.customerLast.value;

  const urlParams = new URLSearchParams(queryString);

  const policyNumberURL = urlParams.get('policy');
  const programCodeURL = urlParams.get('program');
  const stateURL = urlParams.get('state');
  const contactFirstURL = urlParams.get('uploaderFirst');
  const contactLastURL = urlParams.get('uploaderLast');
  const contactEmailURL = urlParams.get('email');
  const isCustomerURL = urlParams.get('isCustomer');
  // const customerFirstURL = urlParams.get('customerFirst');
  // const customerLastURL = urlParams.get('customerLast');
  let compStepList = values.completedStepList;

  if (policyNumberURL === policyNumber && stateURL === state && contactFirstURL === contactFirst && contactLastURL === contactLast && contactEmailURL === contactEmail && isCustomerURL === isCustomer && SAF_POL_PROG_CODES.includes(programCodeURL)) {
    // Checks to see if user is navigating back to the information screen from File Select or Review
    // If so, re-activates correct breadcrumbs
    if (values.completedStep === 0) {
      compStepList = [true, false, false]
    }
    if (values.completedStep === 1) {
      compStepList = [true, true, true]
    }
    setValues((prevValues) => ({
      ...prevValues,
      enableContinueInfoPage: true,
      completedStepList: compStepList
    }))
    return true
  }
  return false
}
    
// Stores given input to corresponding details and checks for validation and whether or not to progress to the next page
// Continue button would be enabled if all requirements pass
const setSafecoPolicyDetailsInput = (event, values, setValues) => {
  let enableContinue = values.enableContinueInfoPage
  let compStepList = values.completedStepList
  const url = new URL(window.location);

  if (event) {
    const { name } = event.target
    let { value } = event.target
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomUrl = queryParams.has('catg');

    if (isCustomUrl && queryParams.get('catg') === 'sfpolicy') {
      if (name === 'safecoPolicyNumber') {
        queryParams.has('policy') ? url.searchParams.set('policy', value) : url.searchParams.append('policy', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'programCode') {
        queryParams.has('program') ? url.searchParams.set('program', value) : url.searchParams.append('program', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'state') {
        queryParams.has('state') ? url.searchParams.set('state', value) : url.searchParams.append('state', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactFirst') {
        queryParams.has('uploaderFirst') ? url.searchParams.set('uploaderFirst', value) : url.searchParams.append('uploaderFirst', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactLast') {
        queryParams.has('uploaderLast') ? url.searchParams.set('uploaderLast', value) : url.searchParams.append('uploaderLast', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactEmail') {
        queryParams.has('email') ? url.searchParams.set('email', value) : url.searchParams.append('email', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'isCustomer') {
        queryParams.has('isCustomer') ? url.searchParams.set('isCustomer', value) : url.searchParams.append('isCustomer', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'customerFirst') {
        queryParams.has('customerFirst') ? url.searchParams.set('customerFirst', value) : url.searchParams.append('customerFirst', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'customerLast') {
        queryParams.has('customerLast') ? url.searchParams.set('customerLast', value) : url.searchParams.append('customerLast', value);
        window.history.pushState(null, '', url.toString());
      }
    }

    // Convert to upper case - auto
    if (event.type === 'blur' && name === 'safecoPolicyNumber' && value.length === 8) {
      if ('ABCDEFHJKLMPRSWXYZ'.includes(value.toUpperCase().charAt(0))) {
        value = value.toUpperCase();
      }  
    }

    // Convert to upper case - home
    if (event.type === 'blur' && name === 'safecoPolicyNumber' && value.length === 9) {
      if (value.toUpperCase().startsWith('O') && 'ABCDEFHJKLMPRSWXYZ'.includes(value.toUpperCase().charAt(1))) {
        value = value.toUpperCase();
      }
    }
  
    // If blur event or selector change, validate input field value
    values.safecoPolicyDetails[name].value = value
    if (event.type === 'blur' || event.target.tagName === 'SELECT' || event.target.id === 'radio-yes' || event.target.id === 'radio-no') {
      values.safecoPolicyDetails[name].error = !values.safecoPolicyDetails[name].valid(value)
      enableContinue = isAllInfoFieldsValid(values)
    }
  } else {
    enableContinue = checkUrlParams(event, values, setValues);
  }
  if (enableContinue === false) {
    compStepList = [false, false, false]
  }

  // Checks to see if user is navigating back to the information screen from File Select or Review
  // If so, re-activates correct breadcrumbs
  if (enableContinue === true && values.completedStep === 0) {
    compStepList = [true, false, false]
  }
  if (enableContinue === true && values.completedStep === 1) {
    compStepList = [true, true, true]
  }

  setValues((prevValues) => ({
    ...prevValues,
    enableContinueInfoPage: enableContinue,
    completedStepList: compStepList
  }))
}

// Checks if the information fields are filled out and valid
// Returns false if any fields have an error or is empty, otherwise returns true
const isAllInfoFieldsValid = (values) => {
  for (const key in values.safecoPolicyDetails) {
    const property = values.safecoPolicyDetails[key]
    if (key !== 'programCode') {
      if (key === 'isCustomer') {
        if (property.error === true || property.value === '') {
          return false
        } else if (property.value === 'no') {
          if ((values.safecoPolicyDetails.customerFirst.error === true || values.safecoPolicyDetails.customerFirst.value === '')
              || (values.safecoPolicyDetails.customerLast.error === true || values.safecoPolicyDetails.customerLast.value === '')) {
            return false
          }
        }
      } else if ((key !== 'customerFirst' && key !== 'customerLast' && key !== 'programCode') && (property.error === true || property.value === '')) {
        return false
      }
    }
  }
  return true
}

export { safecoPolicyDetails, initialSafecoPolicyDetails, validateSafecoPolicyDetails, setSafecoPolicyDetailsInput, isValidSafecoPolicyNumber, isMandatory, isValidEmail, isValidCustomerFirst, isValidCustomerLast, validCustomerSelect, isMandatorySelect }
