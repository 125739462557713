import { React, useContext } from 'react'
import {
  Heading,
  GridRow,
  GridCol,
} from '@lmig/lmds-react'
import { DETAILS } from '../../constants'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Box } from '@mui/material'
import { FormContext } from '../../FormContext/FormContext'

// Renders the overview section in the review page for claims
// Shows the user's claim number, name, email and company (if filled in)
const ClaimsOverview = () => {
  const [values] = useContext(FormContext)
  const isMobile = useCheckIsMobile()
  return (
    <Box>
      <Heading type='h5-bold' style={{ marginBottom: '0px' }}>Overview</Heading>
      <hr style={{ border: '1px solid black' }} />
      <GridRow direction="column" gutterSize='default' gutters='horizontal' style={{width:'100%'}}>
        {Object.keys(values.claimDetails).map((keyName) => (
          isMobile ?
            <div key={keyName} style={{ width: '100%'}}>
              {values.claimDetails[keyName].value !== '' ?
                <GridRow justify='between' key={keyName} style={{ display: 'flex', alignContent: 'space-between', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
                  <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                    <span style={{ wordWrap: 'break-word' }}><strong style={{}}>{`${DETAILS[keyName].reviewLabel}`}</strong></span>
                  </GridCol>
                  <GridCol base={6} style={{ textAlign: 'end', float: 'right'}}>
                    <span style={{ wordBreak: 'break-word' }}>{`${values.claimDetails[keyName].value}`}</span>
                  </GridCol>
                </GridRow> : ''}
            </div>
            :
            <GridCol base={6} justify='between' key={keyName}>
              {values.claimDetails[keyName].value !== '' ?
                
                <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                  <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>{`${DETAILS[keyName].reviewLabel}:`}</strong></span>
                  <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.claimDetails[keyName].value}`}</span>
                </div>
                : ''}
            </GridCol> 
        ))}
      </GridRow>
    </Box>
  )
}

export default ClaimsOverview
