import { useEffect, useState } from 'react'

// returns category based on url query parameter
const useFetchCategoryFromUrl = () => {
  const [urlParams, setUrlParams] = useState({});
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const category = queryParams.get('catg');
    let params = {};

    if (category === 'policy') {
      params = {
        category : queryParams.get('catg') ?  'personal-policy' : '',
        policy : queryParams.get('policy'),
        progCode : queryParams.get('progCode')?.toLowerCase(),
        state : queryParams.get('state')?.toUpperCase(),
        name :  queryParams.get('name'),
        email : queryParams.get('email'),
      }
    }

    if (category === 'claim') {
      params = {
        category : queryParams.get('catg') ?  'personal-claim' : '',
        claim : queryParams.get('claim'),
        name : queryParams.get('name'),
        email : queryParams.get('email'),
        company : queryParams.get('company'),
        vendor : queryParams.get('vendor'),
      }
    }
    
    if (category === 'quote') {
      params = {
        category : queryParams.get('catg') ?  'personal-quote' : '',
        lineOfBusiness : queryParams.get('lineOfBusiness')?.toLowerCase(),
        progCode : queryParams.get('progCode')?.toLowerCase(),
        state : queryParams.get('state')?.toUpperCase(),
        contactFirst : queryParams.get('contactFirst'),
        contactLast : queryParams.get('contactLast'),
        phoneNumber : queryParams.get('phoneNumber'),
        email : queryParams.get('email'),
        isCustomer : queryParams.get('isCustomer')?.toLowerCase(),
        customerFirst : queryParams.get('customerFirst'),
        customerLast : queryParams.get('customerLast'),
        customerEmail : queryParams.get('customerEmail'),
      }
    }

    if (category === 'blpolicy') {
      params = {
        category : queryParams.get('catg') ? 'bl-policy' : '',
        blPolicyNumber : queryParams.get('accountNumber'),
        blPolicyExpYear : queryParams.get('blPolicyExpYear'),
        contactFirst : queryParams.get('contactFirst'),
        contactLast : queryParams.get('contactLast'),
        email : queryParams.get('email'),
      }
    }

    if (category === 'sfpolicy') {
      params = {
        category : queryParams.get('catg') ?  'safeco-policy' : '',
        safecoPolicyNumber : queryParams.get('policy'),
        progCode : queryParams.get('program'),
        state : queryParams.get('state')?.toUpperCase(),
        contactFirst : queryParams.get('uploaderFirst'),
        contactLast : queryParams.get('uploaderLast'),
        email : queryParams.get('email'),
        isCustomer : queryParams.get('isCustomer')?.toLowerCase(),
        customerFirst : queryParams.get('customerFirst'),
        customerLast : queryParams.get('customerLast'),
      }
    }

    setUrlParams(params);
  },[]);
  
  return urlParams;
}

export { useFetchCategoryFromUrl };

