import { Box } from '@mui/material'
import TextInput from '../../Components/TextInput'
import { Button } from '@lmig/lmds-react-button'
import React, { useState } from 'react'
import { GridCol, GridRow, Form, RadioGroup, Radio } from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Heading } from '@lmig/lmds-react-typography'
import { FieldGroup } from '@lmig/lmds-react-field-group'
import { Select, SelectOption } from '@lmig/lmds-react-select'
import { STATE_LIST, ERROR_LIST, SAF_POL_PROG_CODES } from '../../constants'

// First page in the file upload process. 
// User fills out personal information/details.
const SafecoPolicyDetails = (props) => {
  const padding = useCheckIsMobile() ? '10px 0px 0px 0px' : '20px'
  const headingMargin = useCheckIsMobile() ? '0px' : '17px'
  const width = useCheckIsMobile() ? '-webkit-fill-available' : 462
  const [state, setState] = useState(props.values.safecoPolicyDetails.state.value)
  const [hidden, setHidden] = useState(props.values.safecoPolicyDetails.isCustomer.value)
  const [progCode, setProgCode] = useState(props.values.safecoPolicyDetails.programCode.value)


  // Sets the state field
  const handleStateDropdown = (e) => {
    setState(e.target.value)
    props.values.safecoPolicyDetails.state.value = e.target.value;
    props.handleSetValuesDetails('safecoPolicy', e)
  }

  // Sets the values of the customer first and last name when radio button value is changed
  const handleRadioButton = (e) => {
    props.handleSetValuesDetails('safecoPolicy', e)
    props.values.safecoPolicyDetails.customerFirst.value = ''
    props.values.safecoPolicyDetails.customerLast.value = ''
    const url = new URL(window.location);
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomer = props.values.safecoPolicyDetails.isCustomer.value;
    if (queryParams.has('customerFirst') || queryParams.has('customerLast')) {
      if (isCustomer === 'yes') {
        url.searchParams.set('customerFirst', '');
        url.searchParams.set('customerLast', '');
        window.history.pushState(null, '', url.toString());
      }
    }
    setHidden(e.target.value)
  }

  const [isFocus, setisFocus] = useState(false)
  const [lastGoodPN, setlastGoodPN] = useState('')

  // Resets fields if policy type is changed (H or A in the beginning)
  const resetValues = (e) => {
    const val = props.values.safecoPolicyDetails.safecoPolicyNumber.value
    if (isFocus === false) {
      if ((val.length === 9 && (val.startsWith('O'))) || (val.length === 8 && ('ABCDEFHJKLMPRSWXYZ'.includes(val.charAt(0))))) {
        setlastGoodPN(val)
        setisFocus(true)
      }
    } else if (isFocus === true) {
      if ((val.length === 9 && (val.startsWith('O'))) || (val.length === 8 && ('ABCDEFHJKLMPRSWXYZ'.includes(val.charAt(0))))) {
        if ((lastGoodPN.startsWith('O') && !val.startsWith('O')) || (!lastGoodPN.startsWith('O') && val.startsWith('O'))) {
          setlastGoodPN(val)
          props.handleSetValuesDetails('resetContextValues', e)
          props.values.safecoPolicyDetails.safecoPolicyNumber.value = val
          setState('')
          setProgCode('')
          setHidden('')
          setisFocus(false)
        }
      }
    }
  }

  // Sets the program code field
  const handleProgramCodeDropdown = (e) => {
    setProgCode(e.target.value)
    props.values.safecoPolicyDetails.programCode.value = e.target.value;
    props.handleSetValuesDetails('safecoPolicy', e)
  }

  // Renders text input fields for user line of business, state, first name, last name, email, and customer first & last name (if applicable)
  // Once mandatory fields are filled out, user can navigate to the File Selection page by clicking the Continue button
  return (
    <React.Fragment >
      <GridRow data-testid='details'>
        <GridCol>
          <Heading align='left'
            style={{
              fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginLeft: headingMargin, fontStyle: 'normal',
              fontWeight: '400',
              height: '26px',
              fontSize: '16px',
              lineHeight: '26px'
            }} className='subheading-largescreen'>
            Please enter your information to help us manage your files correctly.

          </Heading>
          <Box style={{ padding, fontSize: 'small' }} className='textinput_container' data-testid='box'>
            <Form id="form--default">
              <FieldGroup
                id="fieldgroup--default"
                style={{ marginTop: 0, width, fontSize: 'small' }}

              >
                <TextInput maxLength={14} data-testid='policyNumber' valueKey='safecoPolicyNumber' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} onBlur={resetValues()} />

                <FieldGroup
                  id="fieldgroup--error"
                  isFull
                  disableMargins='all'
                  messages={[
                    {
                      text: `${ERROR_LIST.state}`,
                      type: props.values.safecoPolicyDetails.state.error ? 'error' : null
                    }
                  ]}
                >
                  <Select
                    highlightType={props.values.safecoPolicyDetails.state.error ? 'error' : null}
                    id="state"
                    name="state"
                    labelVisual="State"
                    value={state}
                    isRequired
                    onChange={(e) => handleStateDropdown(e)}
                    onBlur={(e) => props.handleSetValuesDetails('safecoPolicy', e)}
                  >
                    <SelectOption key="none" value="">--Select an option--
                    </SelectOption>
                    {STATE_LIST.map((item) => (
                      <SelectOption key={item.code} value={item.code}>
                        {item.code}
                      </SelectOption>
                    ))}

                  </Select>
                </FieldGroup>

              </FieldGroup>
            </Form>

            <TextInput maxLength={200} data-testid='contactFirst' valueKey='contactFirst' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} />
            <TextInput maxLength={200} data-testid='contactLast' valueKey='contactLast' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} />
            <TextInput maxLength={200} data-testid='contactEmail' valueKey='contactEmail' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} />

            <Heading align='left'
              style={{
                fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginBottom: '3em', marginLeft: '-5px', fontStyle: 'normal',
                fontWeight: '400',
                height: '26px',
                fontSize: '16px',
                lineHeight: '26px'
              }} className='subheading-largescreen'>
              Please leave the following dropdown blank if you have not been provided a specific program name/code.

            </Heading>

            <FieldGroup
              id="fieldgroup--error"
              isFull
              disableMargins='all'
            // messages={[
            //   {
            //     text: `${ERROR_LIST.programCode}`,
            //     type: props.values.policyDetails.programCode.error ? 'error' : null
            //   }
            // ]}
            >
              <Select
                // highlightType={props.values.policyDetails.programCode.error ? 'error' : null}
                id="programCode"
                name="programCode"
                labelVisual="Program"
                value={progCode}
                // isRequired
                onChange={(e) => handleProgramCodeDropdown(e)}
                onBlur={(e) => props.handleSetValuesDetails('safecoPolicy', e)}
              >
                <SelectOption key="none" value="">--Select an option--</SelectOption>
                {SAF_POL_PROG_CODES.map((item) => (
                  (<SelectOption key={item} value={item}>{item}</SelectOption>)
                ))}
              </Select>
            </FieldGroup>

            <RadioGroup name="isCustomer" labelVisual="Are you the customer?" inline={true} isRequired onChange={(e) => handleRadioButton(e)}>
              <Radio checked={hidden === 'yes'} id="radio-yes" labelVisual="Yes" value="yes" />
              <Radio checked={hidden === 'no'} id="radio-no" labelVisual="No" value="no" />
            </RadioGroup>

            {hidden === 'no' &&
              <div><TextInput maxLength={200} data-testid='customerFirst' valueKey='customerFirst' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} />
                <TextInput maxLength={200} data-testid='customerLast' valueKey='customerLast' reason='safecoPolicy' detail={props.values.safecoPolicyDetails} /></div>
            }





            <Button
              disabled={!props.values.enableContinueInfoPage}
              id='continue'
              data-testid='continue'
              isLargeOnMobile={true}
              size="medium"
              type='submit'
              variant='primary'
              dynamicWidth={true}
              style={{ width: '-webkit-fill-available', marginTop: '10px' }}
              /* istanbul ignore next */
              onClick={(e) => props.handleSetValuesDetails('activeStep', e)}
            >
              Continue
            </Button>
          </Box>
        </GridCol>
      </GridRow>
    </React.Fragment>
  )
}

export default SafecoPolicyDetails
