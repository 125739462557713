export const getDoctypeInternalQuote = (category, subcategory) => {
  let docDescription = '';
  let pmcode = '';
  let retentionClass = '';
  let manualIndexing = '';
  let notification = '';

  // Mapping category to subcategory for Quote
  if (category === 'Auto Coverage') {
    switch (true) {
    case subcategory === 'Bodily Injury':
      docDescription = 'Bodily Injury Coverage';
      pmcode = 'PM362';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Personal Injury Protection':
      docDescription = 'PIP Coverage';
      pmcode = 'PM123';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Personal Injury Medical':
      docDescription = 'PIP Medical Selection';
      pmcode = 'PM361';
      retentionClass = 'ACC100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Qualified Health Coverage Document- MI':
      docDescription = 'QHC Documentation';
      pmcode = 'PM364';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  if (category === 'Documentation') {
    switch (true) {
    case subcategory === 'Contractor Estimate':
      docDescription = 'Contractor Estimate';
      pmcode = 'PM247';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Garage Verification':
      docDescription = 'Garage Verification';
      pmcode = 'PM88';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Operator Verification':
      docDescription = 'Operator Verification';
      pmcode = 'PM386';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Photo':
      docDescription = 'Photo';
      pmcode = 'PM388';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Photo of Property':
      docDescription = 'Photo of Property';
      pmcode = 'PM390';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Proof of Homeownership':
      docDescription = 'Proof of Homeownership';
      pmcode = 'PM368';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Proof of Mileage':
      docDescription = 'Rated Annualized Mileage Verification';
      pmcode = 'PM369';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Property Inspection':
      docDescription = 'Property Inspection';
      pmcode = 'PM184';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Proof of Insurance':
      docDescription = 'Certificate of Insurance';
      pmcode = 'PM195';
      retentionClass = 'POL180';
      manualIndexing = 'No';
      notification = 'No';
      break;
    case subcategory === 'Roof Inspection':
      docDescription = 'Roof Inspection';
      pmcode = 'PM387';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Statement of Quote Accuracy':
      docDescription = 'Statement of Quote Accuracy';
      pmcode = 'PM389';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Statement of Residency':
      docDescription = 'Statement of Residency';
      pmcode = 'PM151';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      break;
    case subcategory === 'Utility Bill':
      docDescription = 'Utility Bill';
      pmcode = 'PM390';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Vehicle Registration':
      docDescription = 'Registry of Motor Vehicles';
      pmcode = 'PM185';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    case subcategory === 'Vehicle Title':
      docDescription = 'Registry of Motor Vehicles';
      pmcode = 'PM185';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      break;
    }
  }

  return { docDescription, pmcode, retentionClass, manualIndexing, notification };
}
