export const getDoctypeInternalClaim = (category, subcategory) => {
  let catInternal = '';
  let subcatInternal = '';

  // Mapping category to subcategory for Claim
  if (category === 'Copy of Receipt') {
    switch (true) {
    case subcategory === 'Additional Living Expenses':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'ALE';
      break;
    case subcategory === 'Building':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'BLDG';
      break;
    case subcategory === 'Personal Property':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'PPUN';
      break;
    case subcategory === 'Car Seat':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'Car Seat(s)';
      break;
    case subcategory === 'Hotel':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'Hotel';
      break;
    case subcategory === 'Meal':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'Meal';
      break;
    case subcategory === 'Roof':
      catInternal = 'Expert Report';
      subcatInternal = 'Roof Receipt';
      break;
    case subcategory === 'Tree Removal':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'Tree Removal';
      break;
    case subcategory === 'Utility':
      catInternal = 'Receipts/Invoices';
      subcatInternal = 'Utility';
      break;
    case subcategory === '':
      catInternal = 'Receipts/Invoices';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Receipts/Invoices';
      subcatInternal = '';
    }
  }

  if (category === 'Third Party Reports/Statments') {
    switch (true) {
    case subcategory === 'Accident':
      catInternal = 'Accident Report';
      subcatInternal = '';
      break;
    case subcategory === 'Appraisal':
      catInternal = 'Non Staff Appraisal';
      subcatInternal = '';
      break;
    case subcategory === 'Contracts':
      catInternal = 'Contracts';
      subcatInternal = '';
      break;
    case subcategory === 'Damaged Items':
      catInternal = 'Damaged Items';
      subcatInternal = '';
      break;
    case subcategory === 'Employee Compliment':
      catInternal = 'Correspondece';
      subcatInternal = 'Compliment';
      break;
    case subcategory === 'Engineering':
      catInternal = 'Expert Report';
      subcatInternal = 'Engineer Report';
      break;
    case subcategory === 'Fire':
      catInternal = 'Police/Fire Report';
      subcatInternal = 'Fire Report';
      break;
    case subcategory === 'Insured Statement':
      catInternal = 'Accident Report';
      subcatInternal = 'Insd Statement';
      break;
    case subcategory === 'Investigative Materials':
      catInternal = 'Investigative Materials';
      subcatInternal = '';
      break;
    case subcategory === 'Lien on Account':
      catInternal = 'Lien';
      subcatInternal = '';
      break;
    case subcategory === 'Payroll':
      catInternal = 'Wage Statement';
      subcatInternal = '';
      break;
    case subcategory === 'Police':
      catInternal = 'Police/Fire Report';
      subcatInternal = 'Police Report';
      break;
    case subcategory === 'Professional Services':
      catInternal = 'Expert Report';
      subcatInternal = '';
      break;
    case subcategory === 'Salvage':
      catInternal = 'TL/Salvage';
      subcatInternal = 'Settlement Transmittals';
      break;
    case subcategory === 'Third Party Claimant Damage Review':
      catInternal = 'Completed 3PC Subrogation Damage Review'
      subcatInternal = '';
      break;
    case subcategory === 'Third Party Claimant Statement':
      catInternal = 'Accident Report';
      subcatInternal = 'Clmt Statement';
      break;
    case subcategory === 'Wage Authorization':
      catInternal = 'Wage Authorization';
      subcatInternal = '';
      break;
    case subcategory === 'Witness Statement':
      catInternal = 'Accident Report';
      subcatInternal = 'Witness Statement';
      break;
    case subcategory === '':
      catInternal = 'Accident Report';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Accident Report';
      subcatInternal = '';
    }
  }

  if (category === 'Contractor Estimate/Bill') {
    switch (true) {
    case subcategory === 'Glass Replacement':
      catInternal = 'Glass Bill';
      subcatInternal = '';
      break;
    case subcategory === 'Rental Costs':
      catInternal = 'Rental Bill';
      subcatInternal = '';
      break;
    case subcategory === 'Towing Costs':
      catInternal = 'Towing Bill';
      subcatInternal = '';
      break;
    case subcategory === 'Salvage Yard':
      catInternal = 'TL/Salvage';
      subcatInternal = 'Salvage Yard Bill';
      break;
    case subcategory === 'Structural Estimate':
      catInternal = 'Damaged Items';
      subcatInternal = 'Structural Estimate';
      break;
    case subcategory === '':
      catInternal = 'Miscellaneous Bill';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Miscellaneous Bill';
      subcatInternal = '';
    }
  }

  if (category === 'Medical') {
    switch (true) {
    case subcategory === 'Authorization':
      catInternal = 'Medical Authorization';
      subcatInternal = '';
      break;
    case subcategory === 'Bill':
      catInternal = 'Medical Bill';
      subcatInternal = '';
      break;
    case subcategory === 'Medicare':
      catInternal = 'Medicare';
      subcatInternal = '';
      break;
    case subcategory === 'Records':
      catInternal = 'Medical Record';
      subcatInternal = '';
      break;
    case subcategory === 'Reports':
      catInternal = 'Managed Care Reports';
      subcatInternal = '';
      break;
    case subcategory === '':
      catInternal = 'Medical Record';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Medical Record';
      subcatInternal = '';
    }
  }

  if (category === 'Legal') {
    switch (true) {
    case subcategory === 'Affidavit':
      catInternal = 'Legal';
      subcatInternal = 'Affidavit';
      break;
    case subcategory === 'Attorney Correspondence':
      catInternal = 'Attorney Correspondence';
      subcatInternal = '';
      break;
    case subcategory === 'Bill':
      catInternal = 'Legal Bill';
      subcatInternal = '';
      break;
    case subcategory === 'Deposition':
      catInternal = 'Legal';
      subcatInternal = 'Depositions';
      break;
    case subcategory === 'General Legal':
      catInternal = 'Legal';
      subcatInternal = '';
      break;
    case subcategory === 'Letter of Representation':
      catInternal = 'Attorney Correspondence';
      subcatInternal = 'LOR/PR';
      break;
    case subcategory === 'Release of Claim':
      catInternal = 'Release';
      subcatInternal = '';
      break;
    case subcategory === '':
      catInternal = 'Attorney Correspondence';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Attorney Correspondence';
      subcatInternal = '';
    }
  }

  if (category === 'Photo') {
    switch (true) {
    case subcategory === 'Contents Photo':
      catInternal = 'Photo';
      subcatInternal = 'Contents Photo';
      break;
    case subcategory === 'Injuries':
      catInternal = 'Photo';
      subcatInternal = 'Injuries';
      break;
    case subcategory === 'Scene':
      catInternal = 'Photo';
      subcatInternal = 'Locus Photo';
      break;
    case subcategory === 'Structure/Building':
      catInternal = 'Photo';
      subcatInternal = 'Structure Photo';
      break;
    case subcategory === 'Vehicle Damage':
      catInternal = 'Photo';
      subcatInternal = 'Vehicle Damage';
      break;
    case subcategory === 'Vehicle General':
      catInternal = 'Photo';
      subcatInternal = 'Vehicle';
      break;
    case subcategory === '':
      catInternal = 'Photo';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Photo';
      subcatInternal = '';
    }
  }

  if (category === 'Mitigation') {
    switch (true) {
    case subcategory === 'Certificate of Satisfaction':
      catInternal = 'Mitigation';
      subcatInternal = 'Certificate of Satisfaction';
      break;
    case subcategory === 'Direction of Pay':
      catInternal = 'Mitigation';
      subcatInternal = 'Direction of Pay'
      break;
    case subcategory === 'Drying Log':
      catInternal = 'Mitigation';
      subcatInternal = 'Drying Log';
      break;
    case subcategory === 'Final Estimate':
      catInternal = 'Mitigation';
      subcatInternal = 'Final Estimate';
      break;
    case subcategory === 'Moisture Readings':
      catInternal = 'Mitigation';
      subcatInternal = 'Moisture Readings';
      break;
    case subcategory === 'Photo Report':
      catInternal = 'Mitigation';
      subcatInternal = 'Photo Report';
      break;
    case subcategory === 'Work Authorization':
      catInternal = 'Mitigation';
      subcatInternal = 'Work Authorization';
      break;
    case subcategory === 'W9':
      catInternal = 'W-9';
      subcatInternal = '';
      break;
    case subcategory === '':
      catInternal = 'Mitigation';
      subcatInternal = '';
      break;
    default:
      catInternal = 'Mitigation';
      subcatInternal = '';
    }
  }

  if (category === 'Video') {
    switch (true) {
    case subcategory === 'Dash Cam Footage':
      catInternal = 'Video';
      subcatInternal = 'Dash Cam Footage';
      break;
    }
  }

  return [catInternal, subcatInternal];
}
