/* istanbul ignore file */
import axios from 'axios'
import getEnv from './getEnv'

export const postIngestionData = (formValues) => new Promise((resolve, reject) => {
  const detailsContext = formValues.details
  const reasonContext = formValues.reason
  const uploadsContext = formValues.uploads
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
      'Cache-Control': 'no-cache, no-store',
      Expires : 'Sat, 26 Jul 1997 05:00:00 GMT',
      Pragma : 'no-cache'
    }
  }

  const data = new FormData()

  data.append('supplementId', formValues.supplementId)
  Object.keys(detailsContext).forEach((key) => {
    data.append(key, detailsContext[key].value)
  })

  data.append('supplementTypeOther', reasonContext.freeText)
  data.append('supplementTypeOption', reasonContext.tags)

  for (const file of uploadsContext.files) {
    const fileBlob = dataURItoBlob(file.rawFile)
    data.append(file.rawFile.category, fileBlob, file.rawFile.name)
  }

  axios
    .post(`${getEnv('API')}/righttouch`, data, config)
    .then((response) => (response.status === 200 ? resolve() : reject()))
    .catch((error) => reject(error))
})

export const testapi = () => new Promise((resolve, reject) => {
  axios
    .get(`${getEnv('API')}/righttouch/hello`)
    .then((response) => (response.status === 200 ? resolve() : reject()))
    .catch((error) => reject(error))
})

const dataURItoBlob = (rawFile) => {
  const binary = atob(rawFile.src.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: rawFile.type })
}
