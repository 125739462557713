import getEnv from './getEnv'

const winston = require('winston')
const { detect } = require('detect-browser')

const SIDE_CLIENT = 'client';

const getFormattedLogMessage = (data) => `${SIDE_CLIENT} - ${JSON.stringify(data)}`;

const logger = winston.createLogger({
  transports: [
    new winston.transports.Http({
      path: 'upload/log',
      host: getEnv('HOST'),
      port: getEnv('PORT'),
      ssl: getEnv('SSL')
    })
  ]
})

if (getEnv('ENV') !== 'production') {
  logger.add(new winston.transports.Console())
}

// Only include those fields which are currently allowed to appear in logs
const filterDetailsForLogging = (formValues) => {
  let userObj = {};
  if (formValues.uploadReason === 'personal-claim') {
    userObj = {
      claimNumber: formValues.claimDetails.claimNumber.value,
      Email: formValues.claimDetails.contactEmail.value,
      Name: formValues.claimDetails.contactName.value,
      Company: formValues.claimDetails.companyName.value
    }
  }

  if (formValues.uploadReason === 'personal-policy') {
    userObj = {
      policyNumber: formValues.policyDetails.policyNumber.value,
      state: formValues.policyDetails.state.value,
      Email: formValues.policyDetails.contactEmail.value,
      Name: formValues.policyDetails.contactName.value
    }
  }

  if (formValues.uploadReason === 'personal-quote') {
    userObj = {
      lineOfBusiness: formValues.quoteDetails.lineOfBusiness.value,
      state: formValues.quoteDetails.state.value,
      uploaderFirstName: formValues.quoteDetails.contactFirst.value,
      uploaderLastName: formValues.quoteDetails.contactLast.value,
      customerFirstName: formValues.quoteDetails.customerFirst.value,
      customerLastName: formValues.quoteDetails.customerLast.value,
      Email: formValues.quoteDetails.contactEmail.value
    }
  }

  if (formValues.uploadReason === 'safeco-policy') {
    userObj = {
      safecoPolicyNumber: formValues.safecoPolicyDetails.safecoPolicyNumber.value,
      state: formValues.safecoPolicyDetails.state.value,
      uploaderFirstName: formValues.safecoPolicyDetails.contactFirst.value,
      uploaderLastName: formValues.safecoPolicyDetails.contactLast.value,
      customerFirstName: formValues.safecoPolicyDetails.customerFirst.value,
      customerLastName: formValues.safecoPolicyDetails.customerLast.value,
      Email: formValues.safecoPolicyDetails.contactEmail.value
    }
  }

  if (formValues.uploadReason === 'bl-policy') {
    userObj = {
      blPolicyNumber: formValues.blPolicyDetails.blPolicyNumber.value,
      blPolicyExpYear: formValues.blPolicyDetails.blPolicyExpYear.value,
      contactFirst: formValues.blPolicyDetails.contactFirst.value,
      contactLast: formValues.blPolicyDetails.contactLast.value,
      contactEmail: formValues.blPolicyDetails.contactEmail.value
    }
  }

  return userObj;
};

const getCategory = (formValues) => {
  if (formValues.uploadReason === 'personal-claim') return 'PL Claims';
  if (formValues.uploadReason === 'personal-quote') return 'Liberty Quote';
  if (formValues.uploadReason === 'personal-policy') return 'Liberty Policy';
  if (formValues.uploadReason === 'safeco-policy') return 'Safeco Policy';
  if (formValues.uploadReason === 'bl-policy') return 'BL Policy';
}



const listFileInfo = (files) => files.map((e) => ({
  fileName: e.rawFile.name,
  fileSize: e.rawFile.size,
  category: e.rawFile.category,
  subcategory: e.rawFile.subcategory
}));

const getFullState = (formValues) => {
  const fileInfo = listFileInfo(formValues.uploads.files);
  const filteredDetails = filterDetailsForLogging(formValues);
  switch (formValues.activeStep + 1) {
  case 1:
    return {
      userDetails: filteredDetails
    };
  case 2:
    return {
      userDetails: filteredDetails,
      filesInfo: fileInfo,
      totalUploadSize: formValues.uploads.totalUploadSize
    };
  default:
    return {
      userDetails: filteredDetails,
      filesInfo: fileInfo,
      totalUploadSize: formValues.uploads.totalUploadSize
    };
  }
};

const getState = (formValues) => {
  let state = '';
  switch (true) {
  case formValues.uploadReason === 'personal-claim':
    state = null;
    break;
  case formValues.uploadReason === 'personal-quote':
    state = formValues.quoteDetails.state.value;
    break;
  case formValues.uploadReason === 'personal-policy':
    state = formValues.policyDetails.state.value;
    break;
  case formValues.uploadReason === 'safeco-policy':
    state = formValues.safecoPolicyDetails.state.value;
    break;
  case formValues.uploadReason === 'bl-policy':
    state = null;
    break;
  }
  return state;
}

export const logTransactionStart = (formValues) => {
  const data = {
    transactionId: formValues.transactionId,
    service: 'ds-digital-ingestion-secure-upload-portal',
    host: getEnv('HOST'),
    event: 'transaction_started',
    browserInfo: getBrowserInfo(),
    device: getDeviceInfo(),
    side: 'client'
  };

  logger.info(getFormattedLogMessage(data), data);
}

export const logEnteredStep = (formValues) => {
  let eventName = '';
  if (formValues.activeStep === 0) {
    eventName = 'information_step_completed'
  } else if (formValues.activeStep === 1) {
    eventName = 'fileselection_step_completed'
  }

  const data = {
    transactionId: formValues.transactionId,
    service: 'ds-digital-ingestion-secure-upload-portal',
    host: getEnv('HOST'),
    event: eventName,
    userState: getFullState(formValues),
    side: 'client',
    uploadReason: getCategory(formValues),
    ...(getState(formValues)
      ? { state: getState(formValues) }
      : {}),
  };

  logger.info(getFormattedLogMessage(data), data);
};

// export const logValidateFailed = (formValues) => {
//   logger.info(
//     `client - ${JSON.stringify({
//       transactionId: formValues.transactionId,
//       claimNumber: formValues.details.claimNumber.value,
//       side: 'client',
//       event: 'validate_failed',
//       step: formValues.activeStep + 1,
//       state: getStepState(formValues)
//     })}`
//   )
// }

const getBaseLogData = (formValues) => {
  const totalUploadSize = `${formValues.uploads.totalUploadSize.toFixed(
    3
  )}MB`;
  let data = {};
  if (formValues.uploadReason === 'personal-claim') {
    data = {
      uploadReason: 'PL Claims',
      transactionId: formValues.transactionId,
      service: 'ds-digital-ingestion-secure-upload-portal',
      host: getEnv('HOST'),
      claimNumber: `${formValues.claimDetails.claimNumber.value}`,
      totalUploadSize,
      userState: getFullState(formValues),
      side: 'client'
    };
  }

  if (formValues.uploadReason === 'personal-policy') {
    data = {
      uploadReason: 'Liberty Policy',
      transactionId: formValues.transactionId,
      service: 'ds-digital-ingestion-secure-upload-portal',
      host: getEnv('HOST'),
      policyNumber: `${formValues.policyDetails.policyNumber.value}`,
      totalUploadSize,
      userState: getFullState(formValues),
      side: 'client'
    };
  }

  if (formValues.uploadReason === 'personal-quote') {
    data = {
      uploadReason: 'Liberty Quote',
      transactionId: formValues.transactionId,
      service: 'ds-digital-ingestion-secure-upload-portal',
      host: getEnv('HOST'),
      totalUploadSize,
      userState: getFullState(formValues),
      side: 'client'
    };
  }

  if (formValues.uploadReason === 'safeco-policy') {
    data = {
      uploadReason: 'Safeco Policy',
      transactionId: formValues.transactionId,
      service: 'ds-digital-ingestion-secure-upload-portal',
      host: getEnv('HOST'),
      totalUploadSize,
      userState: getFullState(formValues),
      side: 'client'
    };
  }

  if (formValues.uploadReason === 'bl-policy') {
    data = {
      uploadReason: 'BL Policy',
      transactionId: formValues.transactionId,
      service: 'ds-digital-ingestion-secure-upload-portal',
      host: getEnv('HOST'),
      totalUploadSize,
      userState: getFullState(formValues),
      side: 'client'
    };
  }

  return data;
}

export const logSubmitStarted = (formValues) => {
  const data = {
    ...getBaseLogData(formValues),
    event: 'submit_started',
    device: getDeviceInfo(),
    ...(getState(formValues) ? { state: getState(formValues) } : {})
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logSubmitOk = (formValues) => {
  const data = {
    ...getBaseLogData(formValues),
    event: 'transaction_completed',
    ...(getState(formValues) ? { state: getState(formValues) } : {})
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logSubmitFailed = (formValues, error) => {
  const data = {
    ...getBaseLogData(formValues),
    event: 'submit_failed',
    ...(getState(formValues) ? { state: getState(formValues) } : {}),
    error,
    statusCode: error.response ? error.response.status : '',
  };
  logger.error(getFormattedLogMessage(data), data);
};

export const getBrowserInfo = () => {
  const browser = detect()

  if (browser) {
    return {
      browser: {
        name: browser.name,
        version: browser.version,
        os: browser.os
      }
    }
  } else {
    return 'Browser details not found'
  }
}


export const getDeviceInfo = () => {
  const browser = detect();
  const mobileOsList = ['android', 'avantgo', 'bada', 'blackberry', 'webos', 'ios', 'phone']
  if (browser) {
    const os = browser.os.toLowerCase();
    if (mobileOsList.some((item) => os.includes(item))) {
      return 'mobile/tablet';
    } else {
      return 'desktop/laptop';
    }
  } else {
    return 'device details not found';
  }
}
