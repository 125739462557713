import { isMandatory } from '../constants'

const initialReason = {
  freeText: '',
  freeTextError: false,
  freeTextValid: (freeText) => isMandatory(freeText),
  tags: []
}

const reason = {
  freeText: '',
  freeTextError: false,
  freeTextValid: (freeText) => isMandatory(freeText),
  tags: []
}

// Sets reason text and error, there is an error when invalid
const setReasonText = (event, values, setValues) => {
  const { value } = event.target
  values.reason.freeTextError = !values.reason.freeTextValid(value)
  values.reason.freeText = value
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Sets reason tags based on given event input
const setReasonTags = (event, values, setValues) => {
  values.reason.tags = event
  setValues((prevValues) => ({
    ...prevValues,
    values
  }))
}

// Checks if reason free text is valid and returns true if valid, false otherwise
const validateReason = (values, setValues) => {
  let error = false
  if (reason.freeTextValid) {
    values.reason.freeTextError = !reason.freeTextValid(values.reason.freeText)
    setValues((prevValues) => ({
      ...prevValues,
      values
    }))
    if (values.reason.freeTextError) {
      error = values.reason.freeTextError
    }
    return !error
  }
}

export { reason, initialReason, validateReason, setReasonText, setReasonTags }
