import { isMandatory } from '../constants'

const initialQuoteDetails = {
  lineOfBusiness: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  phoneNumber: {
    value: '',
    error: false,
    valid: (value) => isValidTelephone(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  isCustomer: {
    value: '',
    error: false,
    valid: (value) => validCustomerSelect(value)
  },
  customerFirst: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerFirst(value)
  },
  customerLast: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerLast(value)
  },
  customerEmail: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerEmail(value)
  },
}

const quoteDetails = {
  lineOfBusiness: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactFirst: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactLast: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  phoneNumber: {
    value: '',
    error: false,
    valid: (value) => isValidTelephone(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  isCustomer: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  customerFirst: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerFirst(value)
  },
  customerLast: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerLast(value)
  },
  customerEmail: {
    value: '',
    error: false,
    valid: (value) => isValidCustomerEmail(value)
  },
}

// Validates user e-mail is in the correct format
// Returns true if in correct format, false otherwise
const isValidEmail = (val) => {
  const re =
    /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
  return re.test(val)
}

const isValidCustomerEmail = (values) => {
  if (values.quoteDetails?.isCustomer?.value === 'no') {
    if (!values.quoteDetails.customerEmail.value) {
      return false
    } else {
      const re = /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
      return re.test(values.quoteDetails.customerEmail.value)
    }
  } else if ((values.quoteDetails?.isCustomer?.value === 'yes') ) {
    return true
  } else if (values && typeof values === 'string') {
    const re = /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
    return re.test(values)
  } else {
    return true
  }
}

const isValidTelephone = (val) => {
  if (val !== null && val !== undefined && val.length === 14) {
    const re = /^\([\d]{3}\)[\d]{3}-[\d]{4}$/;
    return re.test(val.replace(/\s+/g, ''));
  } else {
    return false;
  }
};

// Validates the select is not blank, returns false if blank and true if not
const isMandatorySelect = (val) => val !== ''

// Validates users response to 'Are you the customer?'
// User response will be yes or no
const validCustomerSelect = (val) => val === 'yes' || val === 'no'

// Validates customer first name
// Returns true if user user is not customer and the value is not empty
const isValidCustomerFirst = (values) => {
  if (values.quoteDetails?.isCustomer?.value === 'no') {
    if (values.quoteDetails.customerFirst.value === '') {
      return false
    }
  }
  return true
}

// Validates customer last name
// Returns true if user user is not customer and the value is not empty
const isValidCustomerLast = (values) => {
  if (values.quoteDetails?.isCustomer?.value === 'no' ) {
    if (values.quoteDetails.customerLast.value === '') {
      return false
    }
  }
  return true
}

// Returns a boolean and checks if the details are valid and changes error to false
// Controls whether or not user is able to move to the next page
const validateQuoteDetails = (values, setValues) => {
  let error = false
  Object.keys(values.quoteDetails).forEach((key) => {
    const field = values.quoteDetails[key]
    if (field.valid) {
      values.quoteDetails[key].error = !field.valid(field.value)
      setValues((prevValues) => ({
        ...prevValues
      }))
      if (values.quoteDetails[key].error) {
        error = values.quoteDetails[key].error
      }
    }
  })
  return !error
}

// Adding URL paramter code below:
// Checking URL parameters, and creating variables for the values from the InformationScreen.jsx values. Then creating variables from the values after parsing through the URL. We then compare the values for lineOfBusiness, state, contactFirst, contactLast, phoneNumber, email, isCustomer, customerFirst, and customerLast with claimDetails from InformationScreen.jsx
const checkUrlParams = (event, values, setValues) => {
  const queryString = window.location.search;
  const lineOfBusiness = values.quoteDetails.lineOfBusiness.value;
  const state = values.quoteDetails.state.value;
  const contactFirst = values.quoteDetails.contactFirst.value;
  const contactLast = values.quoteDetails.contactLast.value;
  const phoneNumber = values.quoteDetails.phoneNumber.value;
  const contactEmail = values.quoteDetails.contactEmail.value;
  const isCustomer = values.quoteDetails.isCustomer.value;
  const programCode = values.quoteDetails.programCode.value;
  const customerFirst = values.quoteDetails.customerFirst.value;
  const customerLast = values.quoteDetails.customerLast.value;
  const customerEmail = values.quoteDetails.customerEmail.value;

  const urlParams = new URLSearchParams(queryString);

  const lineOfBusinessURL = urlParams.get('lineOfBusiness');
  const stateURL = urlParams.get('state');
  const contactFirstURL = urlParams.get('contactFirst');
  const contactLastURL = urlParams.get('contactLast');
  const phoneNumberURL = urlParams.get('phoneNumber');
  const contactEmailURL = urlParams.get('email');
  const isCustomerURL = urlParams.get('isCustomer');
  const programCodeURL = urlParams.get('progCode');
  const customerFirstURL = urlParams.get('customerFirst');
  const customerLastURL = urlParams.get('customerLast');
  const customerEmailURL = urlParams.get('customerEmail');

  let compStepList = values.completedStepList;

  if (lineOfBusinessURL === lineOfBusiness && lineOfBusinessURL && programCodeURL === programCode && programCodeURL &&
    stateURL === state && stateURL && contactFirstURL === contactFirst && contactFirstURL && contactLastURL === contactLast && contactLastURL &&
    phoneNumberURL === phoneNumber && phoneNumberURL && contactEmailURL === contactEmail && contactEmailURL) {
    // Checks to see if user is navigating back to the information screen from File Select or Review
    // If so, re-activates correct breadcrumbs

    // If isCustomer = no , check for the validity for underlying customer name and email fields before enabling Continue button
    // If isCustomer = yes, No need to check for underlying customer name and email fields. Enable the Continue button
    if (isCustomerURL === isCustomer && isCustomerURL.toLowerCase() === 'no') {
      if (customerFirstURL === customerFirst && customerFirstURL && customerLastURL === customerLast && customerLastURL && customerEmailURL === customerEmail && customerEmailURL) {
        if (values.completedStep === 0) {
          compStepList = [true, false, false]
        }
        if (values.completedStep === 1) {
          compStepList = [true, true, true]
        }
        setValues((prevValues) => ({
          ...prevValues,
          enableContinueInfoPage: true,
          completedStepList: compStepList
        }))
        return true
      }
    } else if (isCustomerURL === isCustomer && isCustomerURL.toLowerCase() === 'yes') {
      if (values.completedStep === 0) {
        compStepList = [true, false, false]
      }
      if (values.completedStep === 1) {
        compStepList = [true, true, true]
      }
      setValues((prevValues) => ({
        ...prevValues,
        enableContinueInfoPage: true,
        completedStepList: compStepList
      }))
      return true
    }
  }
  return false
}

// Stores given input to corresponding details and checks for validation and whether or not to progress to the next page
// Continue button would be enabled if all requirements pass
// URL parameters also update upon changes from input fields
const setQuoteDetailsInput = (event, values, setValues) => {
  let enableContinue = values.enableContinueInfoPage
  let compStepList = values.completedStepList
  const url = new URL(window.location);

  if (event) {
    const { name } = event.target
    const { value } = event.target
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomUrl = queryParams.has('catg');
    
    if (isCustomUrl && queryParams.get('catg') === 'quote') {
      if (name === 'lineOfBusiness') {
        queryParams.has('lineOfBusiness') ? url.searchParams.set('lineOfBusiness', value) : url.searchParams.append('lineOfBusiness', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'programCode') {
        queryParams.has('progCode') ? url.searchParams.set('progCode', value) : url.searchParams.append('progCode', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'state') {
        queryParams.has('state') ? url.searchParams.set('state', value) : url.searchParams.append('state', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactFirst') {
        queryParams.has('contactFirst') ? url.searchParams.set('contactFirst', value) : url.searchParams.append('contactFirst', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactLast') {
        queryParams.has('contactLast') ? url.searchParams.set('contactLast', value) : url.searchParams.append('contactLast', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'phoneNumber') {
        queryParams.has('phoneNumber') ? url.searchParams.set('phoneNumber', value) : url.searchParams.append('phoneNumber', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactEmail') {
        queryParams.has('email') ? url.searchParams.set('email', value) : url.searchParams.append('email', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'isCustomer') {
        queryParams.has('isCustomer') ? url.searchParams.set('isCustomer', value) : url.searchParams.append('isCustomer', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'customerFirst') {
        queryParams.has('customerFirst') ? url.searchParams.set('customerFirst', value) : url.searchParams.append('customerFirst', value);
        window.history.pushState(null, '', url.toString());
      }
    
      if (name === 'customerLast') {
        queryParams.has('customerLast') ? url.searchParams.set('customerLast', value) : url.searchParams.append('customerLast', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'customerEmail') {
        queryParams.has('customerEmail') ? url.searchParams.set('customerEmail', value) : url.searchParams.append('customerEmail', value);
        window.history.pushState(null, '', url.toString());
      }
    }

    // If blur event or selector change, validate input field value
    values.quoteDetails[name].value = value
    if (event.type === 'blur' || event.target.tagName === 'SELECT' || event.target.id === 'radio-yes' || event.target.id === 'radio-no') {
      values.quoteDetails[name].error = !values.quoteDetails[name].valid(value)
      enableContinue = isAllInfoFieldsValid(values)
    }
  } else {
    enableContinue = checkUrlParams(event, values, setValues);
  }

  if (enableContinue === false) {
    compStepList = [false, false, false]
  }

  // Checks to see if user is navigating back to the information screen from File Select or Review
  // If so, re-activates correct breadcrumbs
  if (enableContinue === true && values.completedStep === 0) {
    compStepList = [true, false, false]
  }
  if (enableContinue === true && values.completedStep === 1) {
    compStepList = [true, true, true]
  }

  setValues((prevValues) => ({
    ...prevValues,
    enableContinueInfoPage: enableContinue,
    completedStepList: compStepList
  }))
}

// Checks if the information fields are filled out and valid
// Returns false if any fields have an error or is empty, otherwise returns true
const isAllInfoFieldsValid = (values) => {
  for (const key in values.quoteDetails) {
    const property = values.quoteDetails[key]
    if (key === 'isCustomer') {
      if ((property.error === true || property.value === '')) {
        return false
      } else if (property.value === 'no') {
        if ((values.quoteDetails.customerFirst.error === true || values.quoteDetails.customerFirst.value === '')
          || (values.quoteDetails.customerLast.error === true || values.quoteDetails.customerLast.value === '')
          || (values.quoteDetails.customerEmail.error === true || values.quoteDetails.customerEmail.value === '')) {
          return false
        }
      }
    } else if ((key !== 'customerFirst' && key !== 'customerLast' && key !== 'customerEmail') && (property.error === true || property.value === '')) {
      return false
    }
  }
  return true
}

export { quoteDetails, initialQuoteDetails, validateQuoteDetails, setQuoteDetailsInput, isMandatory, isValidEmail, isValidCustomerFirst, isValidCustomerLast, validCustomerSelect, isMandatorySelect, isValidTelephone, isValidCustomerEmail }
