import { React, useContext } from 'react'
import {
  Heading,
  GridRow,
  GridCol,
} from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Box } from '@mui/material'
import { FormContext } from '../../FormContext/FormContext'

// Renders the overview section in the review page for policy
// Shows the user's policy number, name and email
const PolicyOverview = () => {
  const [values] = useContext(FormContext)
  const isMobile = useCheckIsMobile()
  return (
    <Box>
      <Heading type='h5-bold' style={{ marginBottom: '0px' }}>Overview</Heading>
      <hr style={{ border: '1px solid black' }} />
      <GridRow direction="column" gutterSize='default' gutters='horizontal'>
        {isMobile ?
          <>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '8px' }}>Policy number</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.policyDetails.policyNumber.value}`}</span>
              </GridCol>
            </GridRow>
            {values.policyDetails.programCode.value ? <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '8px' }}>Program Code</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.policyDetails.programCode.value}`}</span>
              </GridCol>
            </GridRow> : ''}
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '8px' }}>State</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px', wordBreak: 'break-word' }}>{`${values.policyDetails.state.value}`}</span>
              </GridCol>
            </GridRow>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '8px' }}>Uploader name</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.policyDetails.contactName.value}`}</span>
              </GridCol>
            </GridRow>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '8px' }}>Uploader email</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px', wordBreak: 'break-word' }}>{`${values.policyDetails.contactEmail.value}`}</span>
              </GridCol>
            </GridRow>
          </>
          :
          <>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Policy number</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.policyDetails.policyNumber.value}`}</span>
              </div>
            </GridCol>
            {values.policyDetails.programCode.value ? <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Program Code</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.policyDetails.programCode.value}`}</span>
              </div>
            </GridCol> : ''}
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>State</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.policyDetails.state.value}`}</span>
              </div>
            </GridCol>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Uploader name</strong></span>
                <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.policyDetails.contactName.value}`}</span>
              </div>
            </GridCol>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Uploader email</strong></span>
                <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.policyDetails.contactEmail.value}`}</span>
              </div>
            </GridCol>
          </>
        }
      </GridRow>
    </Box>
  )
}

export default PolicyOverview
