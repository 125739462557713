import React, { useContext, useState } from 'react'
import { FormContext } from '../../FormContext/FormContext'
import { Box } from '@mui/material'
import {
  Button,
  Heading,
  GridRow,
  GridCol,
  LoadingSpinner,
  Notification
} from '@lmig/lmds-react'
import { DETAILS } from '../../constants'
import FileIncludedSection from './FileIncludedSection'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Modal , ModalFooter , ModalBody } from '@lmig/lmds-react-modal'
import { uploadServiceApi } from '../../uploadServiceApi'
import { logSubmitStarted , logSubmitOk , logSubmitFailed} from '../../logger'
import ClaimsOverview from './ClaimsOverview'
import QuoteOverview from './QuoteOverview'
import PolicyOverview from './PolicyOverview'
import SafecoPolicyOverview from './SafecoPolicyOverview'
import BLPolicyOverview from './BLPolicyOverview'

// Final page in the three step file upload process
const Review = (props) => {
  const [values] = useContext(FormContext)
  const [isUploadError, setIsUploadError] = useState(false)
  const details = values.claimDetails
  const isMobile = useCheckIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const padding = isMobile ? '0px' : '16px'
  const buttonWidth = isMobile ? '175px' : '100%'
  const buttonMargin = isMobile ? '16px' : '40px 0px 0px 0px'

  // verifies if file upload API call is successful
  // shows notification error if upload failed
  // goes to success page if upload is successful
  /* istanbul ignore next */
  const checkFileUploads = async (e) => {
    try {
      setIsUploadError(false);
      setIsOpen(true);
      logSubmitStarted(values);
      const res = await uploadServiceApi(values);
      if (res === 200) {
        setIsOpen(false);
        logSubmitOk(values);
        props.handleSetValuesDetails('activeStep', e);
      } else {
        logSubmitFailed(values, error)
        setIsOpen(false);
        setIsUploadError(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const error = {
          response : {status : res, msg : 'upload failed'}
        } 
      }
    } catch (error) {
      logSubmitFailed(values, error)
      setIsOpen(false);
      setIsUploadError(true);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }

  // Renders the the Overview and Files Included section on the review page for desktop and mbile view
  // Renders pop-up modal indicating file upload when Upload Files button is clicked
  return (
    <Box style={{ padding }} data-testid='review'>
      {isUploadError ? <Notification highlightType='negative' alert="There is an error while uploading, Please try again!" style={{ marginTop: '24px'}} /> : <div></div>}
      {useCheckIsMobile() ?
        <Modal size="small" takeover={['sm', 'md', 'base']} mandatory={true} isOpen={isOpen} onClose={() => setIsOpen(false)} style={{ textAlign: 'center', width: '100vw', height: '100%', margin: '0px', padding: '0px' }}>
          <ModalBody style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '100%', alignItems: 'center', position: 'absolute', top: '63%' }}>
            <Heading type="h4-light" style={{ textAlign: 'center', width: '100%',  paddingBottom: '10px' }}>Uploading your files...</Heading>
          </ModalBody>
          <ModalBody style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '100%', alignItems: 'center', position: 'absolute', top: '90%' }}>           
            <div style={{ width: '303px', position: 'absolute' }}>This could take a minute, so please don't close your browser.</div>
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
              <LoadingSpinner />
            </div>
          </ModalFooter>
        </Modal>
        :
        <Modal size="small" takeover={['sm']} mandatory={true} isOpen={isOpen} onClose={() => setIsOpen(false)} style={{ textAlign: 'center', width:'100%', margin:'0px' }}>
          <ModalBody style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '100%' }}>
            <GridCol base={12}>
              <GridRow style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                <Heading type="h4-light" style={{ textAlign: 'center', width: '100%', padding: '0px', margin: '0px' }}>Uploading your files...</Heading>
              </GridRow>
              <GridRow style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                <div style={{ width: '303px' }}>This could take a minute, so please don't close your browser.</div>
              </GridRow>
              <GridRow style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                <LoadingSpinner />
              </GridRow>
            </GridCol>        
          </ModalBody>
        </Modal>
      }

      {values.uploadReason === 'personal-claim' && <ClaimsOverview></ClaimsOverview>}
      {values.uploadReason === 'personal-quote' && <QuoteOverview></QuoteOverview>}
      {values.uploadReason === 'personal-policy' && <PolicyOverview></PolicyOverview>}
      {values.uploadReason === 'safeco-policy' && <SafecoPolicyOverview></SafecoPolicyOverview>}
      {values.uploadReason === 'bl-policy' && <BLPolicyOverview></BLPolicyOverview>}

      <FileIncludedSection files={values.uploads.files} values={values} />
      <Button data-testid='upload-files' id='uploadFilesButton' onClick={checkFileUploads} isLargeOnMobile size="small" variant="primary" style={{ padding: '9px 16px 8px', margin: buttonMargin, width: buttonWidth }}>Upload files</Button>
    </Box>
  )
}

export default Review
