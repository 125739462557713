import React, { useState } from 'react'
import {
  Heading,
  GridCol,
  GridRow,
  Link
} from '@lmig/lmds-react'
import { imageContent, reviewImageContent, filePreview } from '../../FormContext/UploadsContext'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Modal, ModalHeader, ModalBody } from '@lmig/lmds-react-modal'

// Renders file information on the Review screen under the Files Included section
const FileIncludedView = (props) => {
  const comment = props.file.rawFile.comment.length > 80 ? `${props.file.rawFile.comment.substring(0, 80)}...` : props.file.rawFile.comment
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useCheckIsMobile()
  const border = props.index < 3 ? '' : '1px solid #E6E6E6'
  const fieldDisplay = props.file.rawFile.fieldDisplay.includes('optional') ? props.file.rawFile.fieldDisplay.substring(0, props.file.rawFile.fieldDisplay.length - 10) : props.file.rawFile.fieldDisplay
  const isVideo = props.file.rawFile.type.includes('video')

  // Renders file name, icon, category, subcategory, and comment corresponding to each file for desktop and mobile view
  return (
    <React.Fragment>
      <Modal data-testid='file-view' size="large" isOpen={isOpen} onClose={() => setIsOpen(false)} style={{ textAlign: 'center', padding: '0px', margin: '10px' }}>
        <ModalHeader style={{ padding: '0px', margin: '0px' }}>
          <Heading tag="span" type="h3-light">
            {props.file.rawFile.name}
          </Heading>
        </ModalHeader>
        <ModalBody>
          {filePreview(props.file)}
        </ModalBody>
      </Modal>
      {useCheckIsMobile() ?
        <GridCol justify='between' base={12} style={{ width: '343px', padding: '0px 0px 32px 0px', borderBottom: '1px solid #E6E6E6' }}>

          <GridRow style={{ padding: '0px 0px 6px 0px', marginTop: '0' }}>
            {props.file.rawFile.name.length > 30 ? <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}</p> : <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name}</p>}
          </GridRow>
          <GridRow>
            {imageContent(props.file)}
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px' }}>
            <GridCol style={{ textAlign: 'left' }}>
              <strong>This file is...</strong>
            </GridCol>
            <GridCol style={{ textAlign: 'right' }}>
              {props.file.rawFile.category}
            </GridCol>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px' }}>
            <GridCol base={7} style={{ textAlign: 'left' }}>
              <strong>{fieldDisplay}</strong>
            </GridCol>
            <GridCol style={{ textAlign: 'right' }}>
              {props.file.rawFile.subcategory}
            </GridCol>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px', textAlign: 'left' }}>
            <strong>Comments</strong>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px', width: '100%' }}>
            <div style={{ width: '100%', wordBreak: 'break-all' }}>{comment}</div>
          </GridRow>
        </GridCol>

        :

        <GridCol justify='between' base={4} style={{ width: '343px', padding: '16px 32px 32px 0px', borderTop: border }}>
          <GridRow style={{ padding: '6px 0px 6px 0px' }}>
            {!isVideo ?
              <Link data-testid='link' style={{ textAlign: 'left' }} onClick={(e) => {
                setIsOpen(true)
              }}>{props.file.rawFile.name.length > 30 ? <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}</p> : <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name}</p>}
              </Link> :
              <div>
                {props.file.rawFile.name.length > 30 ? <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}</p> : <p style={{ textAlign: 'left' }}>{reviewImageContent(props.file, isMobile)}{props.file.rawFile.name}</p>}
              </div>
            }
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px' }}>
            <GridCol style={{ textAlign: 'left' }}>
              <strong>This file is...</strong>
            </GridCol>
            <GridCol style={{ textAlign: 'right' }}>
              {props.file.rawFile.category}
            </GridCol>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px' }}>
            <GridCol style={{ textAlign: 'left' }}>
              <strong>{fieldDisplay}</strong>
            </GridCol>
            <GridCol style={{ textAlign: 'right' }}>
              {props.file.rawFile.subcategory}
            </GridCol>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px', textAlign: 'left' }}>
            <strong>Comments</strong>
          </GridRow>
          <GridRow style={{ padding: '6px 0px 6px 0px', width: '100%' }}>
            <div style={{ width: '100%', wordBreak: 'break-all' }}>{props.file.rawFile.comment}</div>
          </GridRow>
          <GridRow>
            {imageContent(props.file)}
          </GridRow>
        </GridCol>
      }
    </React.Fragment>

  )
}

export default FileIncludedView
