import { Box } from '@mui/material'
import TextInput from '../../Components/TextInput'
import { Button } from '@lmig/lmds-react-button'
import React, { useState } from 'react'
import { GridCol, GridRow, Form, RadioGroup, Radio } from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Heading } from '@lmig/lmds-react-typography'
import { FieldGroup } from '@lmig/lmds-react-field-group'
import { Select, SelectOption } from '@lmig/lmds-react-select'
import { STATE_LIST, ERROR_LIST, PROG_CODES } from '../../constants'

// First page in the file upload process. 
// User fills out personal information/details.
const QuoteDetails = (props) => {
  const padding = useCheckIsMobile() ? '10px 0px 0px 0px' : '20px'
  const headingMargin = useCheckIsMobile() ? '0px' : '17px'
  const width = useCheckIsMobile() ? '-webkit-fill-available' : 462
  const [lob, setLob] = useState(props.values.quoteDetails.lineOfBusiness.value)
  const [state, setState] = useState(props.values.quoteDetails.state.value)
  const [hidden, setHidden] = useState(props.values.quoteDetails.isCustomer.value)
  const [progCode, setProgCode] = useState(props.values.quoteDetails.programCode.value)

  // Sets the line of business
  // Clears fields if line of business is changed
  const handleBusinessDropdown = (e) => {
    setLob(e.target.value)
    if (props.values.quoteDetails.lineOfBusiness.value.length && e.target.value !== props.values.quoteDetails.lineOfBusiness.value) {
      props.handleSetValuesDetails('resetContextValues', e);
      setState('')
      setProgCode('')
      setHidden('')
    }
    props.handleSetValuesDetails('quote', e)
  }

  // Sets the state field
  const handleStateDropdown = (e) => {
    setState(e.target.value)
    props.values.quoteDetails.state.value = e.target.value;
    props.handleSetValuesDetails('quote', e)
  }

  // Sets the program code field
  const handleProgramCodeDropdown = (e) => {
    setProgCode(e.target.value)
    props.values.quoteDetails.programCode.value = e.target.value;
    props.handleSetValuesDetails('quote', e)
  }

  // Sets the values of the customer first and last name when radio button value is changed
  const handleRadioButton = (e) => {
    props.handleSetValuesDetails('quote', e)
    props.values.quoteDetails.customerFirst.value = ''
    props.values.quoteDetails.customerLast.value = ''
    props.values.quoteDetails.customerEmail.value = ''
    const url = new URL(window.location);
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomer = props.values.quoteDetails.isCustomer.value;
    if (queryParams.has('customerFirst') || queryParams.has('customerLast') || queryParams.has('customerEmail')) {
      if (isCustomer === 'yes') {
        url.searchParams.set('customerFirst', '');
        url.searchParams.set('customerLast', '');
        url.searchParams.set('customerEmail', '');
        window.history.pushState(null, '', url.toString());
      }
    }
    setHidden(e.target.value)
  }

  // Renders text input fields for user line of business, state, first name, last name, email, and customer first & last name (if applicable)
  // Once mandatory fields are filled out, user can navigate to the File Selection page by clicking the Continue button
  return (
    <React.Fragment >
      <GridRow data-testid='details'>
        <GridCol>
          <Heading align='left'
            style={{
              fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginLeft: headingMargin, fontStyle: 'normal',
              fontWeight: '400',
              height: '26px',
              fontSize: '16px',
              lineHeight: '26px'
            }} className='subheading-largescreen'>
                    Please enter your information to help us manage your files correctly.

          </Heading>
          <Box style={{ padding, fontSize: 'small' }} className='textinput_container' data-testid='box'>
            <Form id="form--default">
              <FieldGroup
                id="fieldgroup--default"
                style={{ marginTop: 0, width, fontSize: 'small' }}
                            
              >
                <Select
                  id='lineOfBusiness'
                  name='lineOfBusiness'
                  isRequired
                  labelVisual="Line of Business"
                  value={lob}
                  onChange={(e) => handleBusinessDropdown(e)}
                >
                  <SelectOption value="">
                    --Select an option--
                  </SelectOption>
                  <SelectOption value="auto">
                    Auto
                  </SelectOption>
                  <SelectOption value="homeowner">
                    Homeowner
                  </SelectOption>
                </Select>

                <FieldGroup
                  id="fieldgroup--error"
                  isFull
                  disableMargins='all'
                  messages={[
                    {
                      text: `${ERROR_LIST.programCode}`,
                      type: props.values.quoteDetails.programCode.error ? 'error' : null
                    }
                  ]}
                >
                  <Select
                    highlightType={props.values.quoteDetails.programCode.error ? 'error' : null}
                    id="programCode"
                    name="programCode"
                    labelVisual="Program Code"
                    value={progCode}
                    isRequired
                    onChange={(e) => handleProgramCodeDropdown(e)}
                    onBlur={(e) => props.handleSetValuesDetails('quote', e)}
                  >
                    <SelectOption key="none" value="">--Select an option--</SelectOption>
                    {PROG_CODES.map((item) => (
                      (<SelectOption key={item.toString()} value={item.toString()}>{item.toString()}</SelectOption>)
                    ))}
                  </Select>
                </FieldGroup>

                <FieldGroup
                  id="fieldgroup--error"
                  isFull
                  disableMargins='all'
                  messages={[
                    {
                      text: `${ERROR_LIST.state}`,
                      type: props.values.quoteDetails.state.error ? 'error' : null
                    }
                  ]}
                >
                  <Select
                    highlightType={props.values.quoteDetails.state.error ? 'error' : null}
                    id="state"
                    name="state"
                    labelVisual="State"
                    value={state}
                    isRequired
                    onChange={(e) => handleStateDropdown(e)}
                    onBlur={(e) => props.handleSetValuesDetails('quote', e)}
                  >
                    <SelectOption key="none" value="">--Select an option--
                    </SelectOption>
                    {STATE_LIST.map((item) => (
                      <SelectOption key={item.code} value={item.code}>
                        {item.code}
                      </SelectOption>
                    ))}
                  </Select>
                </FieldGroup>
              </FieldGroup>
            </Form>

            <TextInput maxLength={200} data-testid='contactFirst' valueKey='contactFirst' reason='quote' detail={props.values.quoteDetails} />
            <TextInput maxLength={200} data-testid='contactLast' valueKey='contactLast' reason='quote' detail={props.values.quoteDetails} />
            <TextInput maxLength={200} data-testid='phoneNumber' valueKey='phoneNumber' reason='quote' detail={props.values.quoteDetails}/>
            <TextInput maxLength={200} data-testid='contactEmail' valueKey='contactEmail' reason='quote' detail={props.values.quoteDetails} />
            <RadioGroup name="isCustomer" labelVisual="Are you the customer?" inline={true} isRequired onChange={(e) => handleRadioButton(e)}>
              <Radio checked={hidden === 'yes'} id="radio-yes" labelVisual="Yes" value="yes" />
              <Radio checked={hidden === 'no'} id="radio-no" labelVisual="No" value="no" />
            </RadioGroup>

            {hidden === 'no' &&
              <div>
                <TextInput maxLength={200} data-testid='customerFirst' valueKey='customerFirst' reason='quote' detail={props.values.quoteDetails}/>
                <TextInput maxLength={200} data-testid='customerLast' valueKey='customerLast' reason='quote' detail={props.values.quoteDetails}/>
                <TextInput maxLength={200} data-testid='customerEmail' valueKey='customerEmail' reason='quote' detail={props.values.quoteDetails} />
              </div>
                
            }
                    
            <Button
              disabled={!props.values.enableContinueInfoPage}
              id='continue'
              data-testid='continue'
              isLargeOnMobile={true}
              size="medium"
              type='submit'
              variant='primary'
              dynamicWidth={true}
              style={{ width: '-webkit-fill-available', marginTop: '10px' }}
              /* istanbul ignore next */
              onClick={(e) => props.handleSetValuesDetails('activeStep', e)}
            >
                        Continue
            </Button>
          </Box>
        </GridCol>
      </GridRow>
    </React.Fragment>
  )
}

export default QuoteDetails
