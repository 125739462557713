import { isMandatory } from '../constants'

const initialClaimDetails = {
  claimNumber: {
    value: '',
    error: false,
    valid: (value) => isValidClaimNumber(value)
  },
  contactName: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  companyName: {
    value: '',
    error: false,
    valid: (value) => true
  }
}

const claimDetails = {
  claimNumber: {
    value: '',
    error: false,
    valid: (value) => isValidClaimNumber(value)
  },
  contactName: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
  companyName: {
    value: '',
    error: false,
    valid: (value) => true
  }
}

// Given a claim number, checks if it ends in 2 or 4 digits
// Returns true if ends in 2 or 4 digits, false otherwise
const isValidClaimNumber = (val) => {
  const re1 = /^\d{9}-\d{4}$/
  const re2 = /^\d{9}-\d{2}$/
  const re3 = /^\d{9}$/

  if (re1.test(val) || re2.test(val) || re3.test(val)) return true
  return false
}

// Validates user e-mail is in the correct format
// Returns true if in correct format, false otherwise
const isValidEmail = (val) => {
  // const re = /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
  const emailRegex = /^[a-zA-Z0-9._%!#$&-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;
  return emailRegex.test(val)
}

// Returns a boolean and checks if the details are valid and changes error to false
// Controls whether or not user is able to move to the next page
const validateClaimDetails = (values, setValues) => {
  let error = false
  Object.keys(values.claimDetails).forEach((key) => {
    const field = values.claimDetails[key]
    if (field.valid) {
      values.claimDetails[key].error = !field.valid(field.value)
      setValues((prevValues) => ({
        ...prevValues
      }))
      if (values.claimDetails[key].error) {
        error = values.claimDetails[key].error
      }
    }
  })
  return !error
}


// Adding URL paramter code below:
// Checking URL parameters, and creating variables for the values from the InformationScreen.jsx values. Then creating variables from the values after parsing through the URL. We then compare the values for claim, name, email, and company with claimDetails from InformationScreen.jsx
const checkUrlParams = (event, values, setValues) => {
  const queryString = window.location.search;
  const claimNumber = values.claimDetails.claimNumber.value;
  const contactName = values.claimDetails.contactName.value;
  const contactEmail = values.claimDetails.contactEmail.value;
  const companyName = values.claimDetails.companyName.value;
  const urlParams = new URLSearchParams(queryString);
  const claim = urlParams.get('claim');
  const name = urlParams.get('name');
  const email = urlParams.get('email');
  const company = urlParams.get('company');
  let compStepList = values.completedStepList
  if (claim === claimNumber && name === contactName && email === contactEmail && company === companyName) {
    // Checks to see if user is navigating back to the information screen from File Select or Review
    // If so, re-activates correct breadcrumbs
    if (values.completedStep === 0) {
      compStepList = [true, false, false]
    }
    if (values.completedStep === 1) {
      compStepList = [true, true, true]
    }
    setValues((prevValues) => ({
      ...prevValues,
      enableContinueInfoPage: true,
      completedStepList: compStepList
    }))
    return true
  }
  return false
}

// Stores given input to corresponding details and checks for validation and whether or not to progress to the next page
// Continue button would be enabled if all requirements pass
// URL parameters also update upon changes from input fields
const setClaimDetailsInput = (event, values, setValues) => {
  let enableContinue = values.enableContinueInfoPage
  let compStepList = values.completedStepList
  const url = new URL(window.location);

  if (event) {
    const {name} = event.target
    const {value} = event.target
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomUrl = queryParams.has('catg');

    if (isCustomUrl && queryParams.get('catg') === 'claim') {
      if (name === 'claimNumber') {
        queryParams.has('claim') ? url.searchParams.set('claim', value) : url.searchParams.append('claim', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactName') {
        queryParams.has('name') ? url.searchParams.set('name', value) : url.searchParams.append('name', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactEmail') {
        queryParams.has('email') ? url.searchParams.set('email', value) : url.searchParams.append('email', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'companyName') {
        queryParams.has('company') ? url.searchParams.set('company', value) : url.searchParams.append('company', value);
        window.history.pushState(null, '', url.toString());
      }
    }
  }

  // If blur event or selector change, validate input field value
  if (event) {
    const { name } = event.target
    let { value } = event.target
    // Append dash after 9 digits have been entered
    // Remove dash when deleting digits and total number of digits is 9 or less
    if (name === 'claimNumber' && value.length === 10 && !(value.includes('-'))) {
      value = `${value.substring(0, 9)}-${value.substring(9)}`
    } else if (name === 'claimNumber' && value.length <= 10 && (value.includes('-'))) {
      value = `${value.replace('-', '')}`
    }
    if (event.type === 'blur' || event.target.tagName === 'SELECT') {
      values.claimDetails[name].error = !values.claimDetails[name].valid(value)

      enableContinue = isAllInfoFieldsValid(values)
    }
    values.claimDetails[name].value = value
  } else {
    enableContinue = checkUrlParams(event, values, setValues);
  }

  if (enableContinue === false) {
    compStepList = [false, false, false]
  }

  // Checks to see if user is navigating back to the information screen from File Select or Review
  // If so, re-activates correct breadcrumbs
  if (enableContinue === true && values.completedStep === 0) {
    compStepList = [true, false, false]
  }
  if (enableContinue === true && values.completedStep === 1) {
    compStepList = [true, true, true]
  }
  setValues((prevValues) => ({
    ...prevValues,
    enableContinueInfoPage: enableContinue,
    completedStepList: compStepList
  }))
}

// Checks if the information fields are filled out and valid
// Returns false if any fields except companyName have an error or is empty, otherwise returns true
const isAllInfoFieldsValid = (values) => {
  for (const key in values.claimDetails) {
    const property = values.claimDetails[key]

    if (key !== 'companyName') {
      if (property.error === true || property.value === '') {
        return false
      }
    }
  }
  return true
}

export { claimDetails, initialClaimDetails, validateClaimDetails, setClaimDetailsInput, isValidClaimNumber, isMandatory, isValidEmail }
