import React, { useState, useEffect, useContext } from 'react'
import { FieldGroup, Select, SelectOption, Caption, GridCol, GridRow, Heading } from '@lmig/lmds-react'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '@lmig/lmds-react-modal'
import { IconTrash } from '@lmig/lmds-react-icons'
import { Textarea } from '@lmig/lmds-react-textarea'
import { FILEVIEW_TEXT } from '../../constants'
import dropdownDataClaim from '../../FileSelectionDataClaim.json'
import dropdownDataBLPolicy from '../../FileSelectionDataBLPolicy.json'
import dropdownDataQuote from '../../FileSelectionDataQuote.json'
import dropdownDataPolicy from '../../FileSelectionDataPolicy.json'
import dropdownDataSafecoPolicy from '../../FileSelectionDataSafecoPolicy.json'
import './FileDataView.css'
import { FormContext } from '../../FormContext/FormContext'
import { Button } from '@lmig/lmds-react-button'
import { imageContent } from '../../FormContext/UploadsContext'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import cloneDeep from 'lodash/cloneDeep'

// Renders the thumbnail and dropdowns after a file is uploaded
// Called by ViewBox to render for every file uploaded
const FileDataView = (props) => {
  const [values, handleSetValues] = useContext(FormContext)
  const [fileTypeOptions, setFileTypeOptions] = useState([])
  const [fileDescOptions, setFileDescOptions] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [fieldDisplay, setFieldDisplay] = useState(props.file.rawFile.fieldDisplay)
  const [fileTypeValue, setFileTypeValue] = useState(props.file.rawFile.category)
  const [fileDescValue, setFileDescValue] = useState(props.file.rawFile.subcategory)
  const [characterCount, setCharacterCount] = useState(props.file.rawFile.comment.length)
  const [fileCommentValue, setFileCommentValue] = useState(props.file.rawFile.comment)


  useEffect(() => {
    const OptionsForFileCategory = getFileTypeOptions(values, dropdownDataClaim, dropdownDataQuote, dropdownDataPolicy, dropdownDataSafecoPolicy, dropdownDataBLPolicy);
    setFileTypeOptions(OptionsForFileCategory);
  }, [values.uploadReason, values.quoteDetails.lineOfBusiness.value])


  useEffect(() => {
    setFileTypeValue(props.file.rawFile.category)
    setFileDescValue(props.file.rawFile.subcategory)
    setFileCommentValue(props.file.rawFile.comment)
    setCharacterCount(props.file.rawFile.comment.length)
    setFieldDisplay(props.file.rawFile.fieldDisplay)
  }, [props.file])


  useEffect(() => {
    const fileTypesList = getFileTypeOptions(values, dropdownDataClaim, dropdownDataQuote, dropdownDataPolicy, dropdownDataSafecoPolicy, dropdownDataBLPolicy);
    if (!props.file.rawFile.category) return false
    const fileDescList = fileTypesList.find((item) => item.value === props.file.rawFile.category)
    setFieldDisplay(fileDescList.fieldDisplay)
    setFileDescOptions(fileDescList.subTypes)
  }, [fileTypeValue, fileDescValue])


  // Sets file category and field display when changed
  const handleFileTypeValues = (e) => {
    if (!e.target.value) {
      setFileDescOptions([])
      setFieldDisplay(props.file.rawFile.fieldDisplay)
      setFileTypeValue(props.file.rawFile.category)
      setFileDescValue(props.file.rawFile.subcategory)

      return false
    }

    const fileTypesList = getFileTypeOptions(values, dropdownDataClaim, dropdownDataQuote, dropdownDataPolicy, dropdownDataSafecoPolicy, dropdownDataBLPolicy);
    const fileDescList = fileTypesList.find((item) => item.value === e.target.value)

    setFileDescOptions(fileDescList.subTypes)
    setFieldDisplay(fileDescList.fieldDisplay)
    const display = fileDescList.fieldDisplay
    setFileTypeValue(e.target.value)
    setFileDescValue(fileDescValue)
    handleSetValues('dropdownAdded', { index: props.index, category: e.target.value, fieldDisplay: display })
  }

  // sets file subcategory when second dropdown is changed
  const handleFileDescValues = (e) => {
    setFileDescValue(e.target.value)
    handleSetValues('dropdownAdded', { index: props.index, category: fileTypeValue, subcategory: e.target.value, fieldDisplay })
  }

  // sets file comment when changed
  const handleComment = (e) => {
    setFileCommentValue(e.target.value.replaceAll('<', '').replaceAll('>', ''))
    setCharacterCount(e.target.value.length)

    handleSetValues('commentAdded', { index: props.index, comment: e.target.value })
  }

  // deletes chosen file
  const removeFile = (e) => {
    setIsOpen(false)
    handleSetValues('filesRemoved', props.file.rawFile.id)
    setFileTypeValue('')
    setFileDescValue('')
    setFileCommentValue('')
  }

  // Renders the file list on the upload page, including the dropdowns, file thumbnail, and comment section
  // Also renders the delete modal if the delete button is clicked
  return (
    <React.Fragment>
      {/* Start of delete modal*/}
      {useCheckIsMobile() ?
        <>
          <hr style={{ marginTop: '22px', marginLeft: '-2px', marginBottom: '16px' }} />
          <Modal data-testid='delete' size="small" takeover={['sm', 'md', 'base']} mandatory={true} isOpen={isOpen} style={{ textAlign: 'center', margin: '0px', padding: '0px' }}>
            <ModalBody style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '100%', alignItems: 'center', position: 'absolute', top: '7em', paddingBottom: '10px' }}>
              <GridCol>
                <GridRow>
                  {props.file.rawFile.name.length > 30 ? <Heading type="h4-light" style={{ textAlign: 'center', width: '100%', paddingBottom: '10px' }}>Are you sure you want to delete {props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}?</Heading> : <Heading type="h4-light" style={{ textAlign: 'center', width: '100%', paddingBottom: '10px' }}>Are you sure you want to delete {props.file.rawFile.name}?</Heading>}

                </GridRow>
                <GridRow style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                  <Button data-testid='remove' variant="primary" onClick={removeFile} style={{ width: '100%', textAlign: 'center' }}>
                    Yes, delete it
                  </Button>
                </GridRow>
                <GridRow style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', margin: '0px' }}>
                  <Button data-testid='cancel' onClick={() => setIsOpen(false)} style={{ width: '100%', textAlign: 'center' }}>No, cancel</Button>
                </GridRow>
              </GridCol>
            </ModalBody>
          </Modal>
        </>
        :
        <>
          <hr style={{ marginTop: '22px', marginLeft: '21px', marginRight: '21px' }} />
          <Modal data-testid='delete' size="small" isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalHeader style={{ textAlign: 'center', width: '100%', margin: '0px 0px 10px 0px' }}>
              {props.file.rawFile.name.length > 30 ? <Heading type="h4-light">Are you sure you want to delete {props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}? </Heading> : <Heading type="h4-light">Are you sure you want to delete {props.file.rawFile.name}? </Heading>}

            </ModalHeader>
            <ModalFooter style={{ textAlign: 'center', width: '100%', margin: '0px' }}>
              <Button data-testid='remove' variant="primary" onClick={removeFile}>
                Yes, delete it
              </Button>
              <Button data-testid='cancel' onClick={() => setIsOpen(false)}>No, cancel</Button>
            </ModalFooter>
          </Modal>
        </>}
      {/* End of delete modal*/}

      {useCheckIsMobile() ?
        <GridRow gutters={true} gutterSize='small' style={{ marginBottom: '25px' }}>
          <GridRow style={{ display: 'flex', alignContent: 'space-between', width: '100%' }} >
            <GridCol base={9}>
              {props.file.rawFile.name.length > 30 ? <div style={{ fontSize: 'small', textAlign: 'left', marginBottom: '10px' }}>{props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}</div> : <div style={{ fontSize: 'small', textAlign: 'left', marginBottom: '10px' }}>{props.file.rawFile.name}</div>}
              <div style={{ textAlign: 'left' }}>{imageContent(props.file)}</div>
            </GridCol>
            <GridCol base={3} style={{ textAlign: 'end' }}>
              <IconTrash data-testid='trash' size="24" color="teal" style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)} />
            </GridCol>
          </GridRow>
          <GridRow >
            <GridRow style={{ width: '100%' }}>
              <GridRow style={{ width: '100%' }}>
                <FieldGroup
                  isFull
                  disableMargins='all'
                  style={{ width: '100%' }}
                >
                  <Select
                    labelVisual='The file is for what?'
                    name='fileType'
                    data-testid='category'
                    id='fileType'
                    value={fileTypeValue}
                    onChange={(e) => handleFileTypeValues(e)}
                    isRequired
                  >
                    {fileTypeOptions.map((item, index) => (
                      <SelectOption key={index} value={item.value}>
                        {item.label}
                      </SelectOption>
                    ))}
                  </Select>
                </FieldGroup>
              </GridRow>
              <GridRow style={{ width: '100%' }}>
                <div style={{ flex: 8, fontSize: 'small' }}>
                  <FieldGroup
                    isFull
                    disableMargins='all'
                    style={{ width: '100%' }}
                  >
                    <Select
                      labelVisual={fieldDisplay}
                      name='fileSubType'
                      id='fileSubType'
                      data-testid='subcategory'
                      value={fileDescValue}
                      onChange={(e) => handleFileDescValues(e)}
                    >
                      {fileDescOptions.map((item, index) => (
                        <SelectOption key={index} value={item.value}>
                          {item.label}
                        </SelectOption>
                      ))}
                    </Select>
                  </FieldGroup>
                </div>
              </GridRow>
            </GridRow>
            <GridRow style={{ width: '100%' }}>
              <GridRow style={{ width: '100%' }}>
                <div className='commentbox-text'>{FILEVIEW_TEXT}</div>
              </GridRow>
            </GridRow>
            <GridRow style={{ width: '100%' }}>
              <GridRow style={{ width: '100%' }}>
                <div style={{ marginTop: '20px', width: '100%' }}>
                  <Textarea data-testid='comment' maxLength={200} resize="none" className='text-area' value={fileCommentValue} onChange={(e) => handleComment(e)} />
                  <Caption style={{ textAlign: 'right' }}><p>{characterCount}/200</p></Caption>
                </div>
              </GridRow>
            </GridRow>
          </GridRow>

        </GridRow>
        :
        <GridRow data-testid='data-view' gutters={true} gutterSize='small' style={{ marginBottom: '25px', marginLeft: '21px' }}>
          <GridCol base={3}>
            {props.file.rawFile.name.length > 30 ? <div style={{ fontSize: 'small', textAlign: 'left', marginBottom: '10px' }}>{props.file.rawFile.name.substring(0, 30)}...{props.file.rawFile.name.split('.').pop()}</div> : <div style={{ fontSize: 'small', textAlign: 'left', marginBottom: '10px' }}>{props.file.rawFile.name}</div>}
            <div>{imageContent(props.file)}</div>
          </GridCol>
          <GridCol base={8}>
            <GridRow >
              <GridCol base={6}>
                <FieldGroup
                  isFull
                  disableMargins='all'
                  data-testid='category'
                >
                  <Select
                    labelVisual='The file is for what?'
                    name='fileType'
                    id='fileType'
                    data-testid='type'
                    value={fileTypeValue}
                    onChange={(e) => handleFileTypeValues(e)}
                    isRequired
                  >
                    <SelectOption value=''>--Select an option--
                    </SelectOption>
                    {fileTypeOptions.map((item, index) => (
                      <SelectOption data-testid={item.value} key={index} value={item.value}>
                        {item.label}
                      </SelectOption>
                    ))}
                  </Select>
                </FieldGroup>
              </GridCol>
              <GridCol base={6}>
                <div style={{ flex: 8, marginLeft: '15px', fontSize: 'small' }}>
                  <FieldGroup
                    isFull
                    disableMargins='all'
                    data-testid='subcategory'
                  >
                    <Select
                      labelVisual={fieldDisplay}
                      name='fileSubType'
                      data-testid='subtype'
                      id='fileSubType'
                      value={fileDescValue}
                      onChange={(e) => handleFileDescValues(e)}
                    >
                      {fileDescOptions.map((item, index) => (
                        <SelectOption key={index} value={item.value}>
                          {item.label}
                        </SelectOption>
                      ))}
                    </Select>
                  </FieldGroup>
                </div>
              </GridCol>
            </GridRow>
            <GridRow >
              <GridCol base={12}>
                <div className='commentbox-text'>{FILEVIEW_TEXT}</div>
              </GridCol>
            </GridRow>
            <GridRow >
              <GridCol base={12}>
                <div style={{ marginTop: '20px' }}>
                  <Textarea data-testid='comment' maxLength={200} resize="none" className='text-area' value={fileCommentValue} onChange={(e) => handleComment(e)} />
                  <Caption style={{ textAlign: 'right' }}><p>{characterCount}/200</p></Caption>
                </div>
              </GridCol>
            </GridRow>
          </GridCol>
          <GridCol base={1} >
            <div className="icon-wrapper trash">
              <IconTrash data-testid='trash' size="24" color="teal" style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)} />
            </div>
          </GridCol>
        </GridRow>
      }
    </React.Fragment>
  )
}

// Function returns the list of items for file category dropdown based on the upload reason and 
// line of business (in case of quote in progress).
const getFileTypeOptions = (valuesObj, claimsJsonData, quoteJsonData, policyJsonData, safecoPolicyJsonData, BLPolicyJsonData) => {
  let fileTypeOptionsList = [];

  // check for vendor mitigation in the URL params for claims
  // If mitigation vendor , create a filtered list of dropdown 1 else publish full list
  const queryParams = new URLSearchParams(window.location.search);
  const vendor = queryParams.get('vendor') || '';

  switch (valuesObj.uploadReason) {
  case 'personal-claim':
    fileTypeOptionsList = cloneDeep(claimsJsonData.fileTypes);
    if (vendor === 'mitigation') {
      fileTypeOptionsList = cloneDeep(claimsJsonData.fileTypes).filter((e) => e.label === 'Mitigation')
    }
    break;
  case 'safeco-policy':
    if (valuesObj.safecoPolicyDetails.safecoPolicyNumber.value.length === 9 && valuesObj.safecoPolicyDetails.safecoPolicyNumber.value.startsWith('O')) {
      const fileTypesHomeownerSafecoPolicy = safecoPolicyJsonData.fileTypes.filter((item) => item.lob === 'homeowner');
      fileTypeOptionsList = cloneDeep(fileTypesHomeownerSafecoPolicy);
    }
    if (valuesObj.safecoPolicyDetails.safecoPolicyNumber.value.length === 8) {
      const fileTypesAutoSafecoPolicy = safecoPolicyJsonData.fileTypes.filter((item) => item.lob === 'auto');
      fileTypeOptionsList = cloneDeep(fileTypesAutoSafecoPolicy);
    }
    break;
  case 'personal-policy':
    if (valuesObj.policyDetails.policyNumber.value.startsWith('H')) {
      const fileTypesHomeownerPolicy = policyJsonData.fileTypes.filter((item) => item.lob === 'homeowner');
      fileTypeOptionsList = cloneDeep(fileTypesHomeownerPolicy);
    }
    if (valuesObj.policyDetails.policyNumber.value.startsWith('A')) {
      const fileTypesAutoPolicy = policyJsonData.fileTypes.filter((item) => item.lob === 'auto');
      fileTypeOptionsList = cloneDeep(fileTypesAutoPolicy);
    }
    break;
  case 'personal-quote':
    fileTypeOptionsList = cloneDeep(quoteJsonData.fileTypes);
    break;
  case 'bl-policy':
    fileTypeOptionsList = cloneDeep(BLPolicyJsonData.fileTypes);
    break;
  }

  return fileTypeOptionsList;
}

export default FileDataView
