import { useEffect, useState } from 'react'
const MOBILE_WIDTH = 820

// returns true if the screen width is less than the designated MOBILE_WIDTH, false otherwise
const useCheckIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (width <= MOBILE_WIDTH)
}

export { useCheckIsMobile }
