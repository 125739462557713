import { useContext } from 'react'
import { FormContext } from '../FormContext/FormContext'
import { AlphanumericInput, FieldGroup } from '@lmig/lmds-react'
import { DETAILS } from '../constants'

// Component for the information page that the user can type input in. Collects user information prior to uploading files.
const TextInput = (props) => {
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext)
  const details = props.detail
  const { valueKey } = props
  
  return (
    <FieldGroup
      isFull
      disableMargins='all'
      messages={setErrorMsg(props.reason, [valueKey])}
      messagesRole='status'
    >
      <AlphanumericInput
        labelVisual={DETAILS[valueKey].label}
        name={valueKey}
        id={valueKey}
        data-testid={valueKey}
        placeholder={DETAILS[valueKey].placeholder}
        value={details[valueKey].value}
        type={DETAILS[valueKey].type}
        mask={DETAILS[valueKey].mask}
        filter={DETAILS[valueKey].filter}
        onChange={(e) => handleSetValues(props.reason, e)}
        onBlur={(e) => handleSetValues(props.reason, e)}
        highlightType={details[valueKey].error ? 'error' : null}
      />
    </FieldGroup>
  )
}

export default TextInput
