import { Box } from '@mui/material'
import TextInput from '../../Components/TextInput'
import { Button } from '@lmig/lmds-react-button'
import React from 'react'
import { GridCol, GridRow } from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Heading } from '@lmig/lmds-react-typography'

// First page in the file upload process. 
// User fills out personal information/details.
const ClaimsDetails = (props) => {
  const padding = useCheckIsMobile() ? '10px 0px 0px 0px' : '20px'
  const headingMargin = useCheckIsMobile() ? '0px' : '17px'

  // Renders text input fields for user claim number, full name, email, and company
  // Once mandatory fields are filled out, user can navigate to the File Selection page by clicking the Continue button
  return (
    <React.Fragment >
      <GridRow data-testid='details'>
        <GridCol>
          <Heading align='left'
            style={{
              fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginLeft: headingMargin, fontStyle: 'normal',
              fontWeight: '400',
              height: '26px',
              fontSize: '16px',
              lineHeight: '26px'
            }} className='subheading-largescreen'>
            Please enter your information to help us manage your files correctly.

          </Heading>
          <Box style={{ padding, fontSize: 'small' }} className='textinput_container' data-testid='box'>
            <TextInput data-testid='claimNumber' valueKey='claimNumber' reason='claims' detail={props.values.claimDetails}/>
            <TextInput maxLength={200} data-testid='contactName' valueKey='contactName' reason='claims' detail={props.values.claimDetails}/>
            <TextInput maxLength={200} data-testid='contactEmail' valueKey='contactEmail' reason='claims' detail={props.values.claimDetails}/>
            <TextInput maxLength={200} data-testid='companyName' valueKey='companyName' reason='claims' detail={props.values.claimDetails}/>

            <Button
              disabled={!props.values.enableContinueInfoPage}
              id='continue'
              data-testid='continue'
              isLargeOnMobile={true}
              size="medium"
              type='submit'
              variant='primary'
              dynamicWidth={true}
              style={{ width: '-webkit-fill-available', marginTop: '10px' }}
              /* istanbul ignore next */
              onClick={(e) => props.handleSetValuesDetails('activeStep', e)}
            >
              Continue
            </Button>
          </Box>
        </GridCol>
      </GridRow>
    </React.Fragment>
  )
}

export default ClaimsDetails
