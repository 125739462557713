import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, Form, IconInfo, IconButton, Popover } from '@lmig/lmds-react'
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile'
import { FieldGroup } from '@lmig/lmds-react-field-group'
import { Select, SelectOption } from '@lmig/lmds-react-select'
import ClaimsDetails from '../Pages/Details/ClaimsDetails'
import PolicyDetails from '../Pages/Details/PolicyDetails'
import QuoteDetails from '../Pages/Details/QuoteDetails'
import BLPolicyDetails from '../Pages/Details/BLPolicyDetails'
import SafecoPolicyDetails from '../Pages/Details/SafecoPolicyDetails'
import { useFetchCategoryFromUrl } from '../Hooks/UseFetchCategoryFromUrl'
import './honeyPot.css'
import { LIB_POL_PROG_CODES, SAF_POL_PROG_CODES } from '../../src/constants.js'


// First page in the file upload process. 
// User fills out personal information/details.
const InformationScreen = (props) => {
  const width = useCheckIsMobile() ? '-webkit-fill-available' : 480
  const pl = useCheckIsMobile() ? 0 : 18
  const mT = useCheckIsMobile() ? '20px' : '0'
  const [showInput, setShowInput] = useState(props.values.uploadReason)
  const urlParams = useFetchCategoryFromUrl()
  const isValidQueryParm = urlParams.category === 'personal-claim' || urlParams.category === 'personal-quote' || urlParams.category === 'personal-policy' || urlParams.category === 'safeco-policy' || urlParams.category === 'bl-policy';

  // Values are stored here based on user input for Policy
  // const policyNumberUpdate = props.values.policyDetails.policyNumber.value;
  // const policyContactNameUpdate = props.values.policyDetails.contactName.value;
  // const policyContactEmailUpdate = props.values.policyDetails.contactEmail.value;

  useEffect(() => {
    if (isValidQueryParm) {
      setShowInput(urlParams.category);
      props.values.uploadReason = urlParams.category;

      if (urlParams.category === 'personal-policy') {
        props.values.policyDetails.policyNumber.value = urlParams.policy || '';
        // if this URL param === program code constant
        if (LIB_POL_PROG_CODES.includes(parseInt(urlParams.progCode))) {
          props.values.policyDetails.programCode.value = urlParams.progCode || '';
        } else {
          // giving an empty value so that the continue button is enabled
          props.values.policyDetails.programCode.value = '';
        }
        props.values.policyDetails.state.value = urlParams.state || '';
        props.values.policyDetails.contactName.value = urlParams.name || '';
        props.values.policyDetails.contactEmail.value = urlParams.email || '';
        // Added to make the setPolicyDetailsInput called in PolicyContext.js
        props.handleSetValuesDetails('policy');
      }

      if (urlParams.category === 'personal-claim') {
        props.values.claimDetails.claimNumber.value = urlParams.claim || '';
        props.values.claimDetails.contactName.value = urlParams.name || '';
        props.values.claimDetails.contactEmail.value = urlParams.email || '';
        props.values.claimDetails.companyName.value = urlParams.company || '';
        // Added to make the setClaimDetailsInput called in ClaimsContext.js
        props.handleSetValuesDetails('claims');
      }

      if (urlParams.category === 'personal-quote') {
        props.values.quoteDetails.lineOfBusiness.value = urlParams.lineOfBusiness || '';
        props.values.quoteDetails.state.value = urlParams.state || '';
        props.values.quoteDetails.contactFirst.value = urlParams.contactFirst || '';
        props.values.quoteDetails.contactLast.value = urlParams.contactLast || '';
        props.values.quoteDetails.phoneNumber.value = urlParams.phoneNumber || '';
        props.values.quoteDetails.contactEmail.value = urlParams.email || '';
        props.values.quoteDetails.isCustomer.value = urlParams.isCustomer || '';
        props.values.quoteDetails.customerFirst.value = urlParams.customerFirst || '';
        props.values.quoteDetails.customerLast.value = urlParams.customerLast || '';
        props.values.quoteDetails.programCode.value = urlParams.progCode || '';
        props.values.quoteDetails.customerEmail.value = urlParams.customerEmail || '';
        // Added to make the setQuoteDetailsInput called in QuoteContext.js
        props.handleSetValuesDetails('quote');
      }

      if (urlParams.category === 'bl-policy') {
        props.values.blPolicyDetails.blPolicyNumber.value = urlParams.blPolicyNumber || '';
        props.values.blPolicyDetails.blPolicyExpYear.value = urlParams.blPolicyExpYear || '';
        props.values.blPolicyDetails.contactFirst.value = urlParams.contactFirst || '';
        props.values.blPolicyDetails.contactLast.value = urlParams.contactLast || '';
        props.values.blPolicyDetails.contactEmail.value = urlParams.email || '';
        // Added to make the setBLPolicyDetailsInput called in BLPolicyContext.js
        props.handleSetValuesDetails('blpolicy')
      }

      if (urlParams.category === 'safeco-policy') {
        props.values.safecoPolicyDetails.safecoPolicyNumber.value = urlParams.safecoPolicyNumber || '';
        // if this URL param === program code constant
        if (SAF_POL_PROG_CODES.includes(urlParams.progCode)) {
          props.values.safecoPolicyDetails.programCode.value = urlParams.progCode || '';
        } else {
          // giving an empty value so that the continue button is enabled
          props.values.safecoPolicyDetails.programCode.value = '';
        }
        props.values.safecoPolicyDetails.state.value = urlParams.state || '';
        props.values.safecoPolicyDetails.contactFirst.value = urlParams.contactFirst || '';
        props.values.safecoPolicyDetails.contactLast.value = urlParams.contactLast || '';
        props.values.safecoPolicyDetails.contactEmail.value = urlParams.email || '';
        props.values.safecoPolicyDetails.isCustomer.value = urlParams.isCustomer || '';
        props.values.safecoPolicyDetails.customerFirst.value = urlParams.customerFirst || '';
        props.values.safecoPolicyDetails.customerLast.value = urlParams.customerLast || '';
        // Added to make the setSafecoPolicyDetailsInput called in SafecoPolicyContext.js
        props.handleSetValuesDetails('safecoPolicy')
      }
    }
  }, [urlParams]);

  const handleDropdown = (e) => {
    if (urlParams.category && urlParams.category !== e.target.value) {
      const path = window.location.href.split('?')[0];
      window.history.replaceState(null, '', path);
    }

    // Resets all values if the upload reason is changed
    setShowInput(e.target.value)
    props.values.uploadReason = e.target.value
    props.handleSetValuesDetails('resetContextValues', e)
  }

  const handleBotInput = (e) => {
    props.handleSetValuesDetails('botInput', e)
  }

  // Renders text input fields for user claim number, full name, email, and company
  // Once mandatory fields are filled out, user can navigate to the File Selection page by clicking the Continue button
  return (
    <React.Fragment >
      <GridRow data-testid='informationScreen'>
        <GridCol >
          <Box style={{ fontSize: 'small' }} className='textinput_container' data-testid='box'>
            <Form id="form--default">
              <FieldGroup
                id="fieldgroup--default"
                style={{ paddingLeft: pl, marginTop: mT, marginBottom: 15, width, fontSize: 'small' }}
                onChange={(e) => handleDropdown(e)}
              >
                <Select
                  id="uploadReason"
                  isRequired
                  labelVisual="Upload Reason"
                  data-testid='uploadReason'
                  value={showInput}
                >
                  <SelectOption hidden>
                  </SelectOption>
                  <SelectOption value="personal-claim">
                    Property/Casualty Claim
                  </SelectOption>
                  <SelectOption value="personal-policy">
                    Liberty Property/Casualty Policy or Policy Change
                  </SelectOption>
                  <SelectOption value="personal-quote">
                    Liberty Property/Casualty Insurance Quote
                  </SelectOption>
                  <SelectOption value="bl-policy">
                    Liberty Business Lines Policy or Policy Change
                  </SelectOption>
                  <SelectOption value="safeco-policy">
                    Safeco Property/Casualty Policy or Policy Change
                  </SelectOption>
                </Select>
              </FieldGroup>
            </Form>
          </Box>
        </GridCol>
      </GridRow>

      <label className='fieldInput' htmlFor="name"></label>
      <input className='fieldInput' autoComplete="new-password" type="text" id="name" name="name" placeholder="Your name here" onChange={(e) => handleBotInput(e)} />
      <label className='fieldInput' htmlFor="email"></label>
      <input className='fieldInput' autoComplete="new-password" type="email" id="email" name="email" placeholder="Your e-mail here" onChange={(e) => handleBotInput(e)}></input>

      {props.values.uploadReason === 'personal-claim' && <ClaimsDetails handleSetValuesDetails={props.handleSetValuesDetails} values={props.values}></ClaimsDetails>}
      {props.values.uploadReason === 'personal-policy' && <PolicyDetails handleSetValuesDetails={props.handleSetValuesDetails} values={props.values}></PolicyDetails>}
      {props.values.uploadReason === 'personal-quote' && <QuoteDetails handleSetValuesDetails={props.handleSetValuesDetails} values={props.values}></QuoteDetails>}
      {props.values.uploadReason === 'bl-policy' && <BLPolicyDetails handleSetValuesDetails={props.handleSetValuesDetails} values={props.values}></BLPolicyDetails>}
      {props.values.uploadReason === 'safeco-policy' && <SafecoPolicyDetails handleSetValuesDetails={props.handleSetValuesDetails} values={props.values}></SafecoPolicyDetails>}
    </React.Fragment>
  )
}

export default InformationScreen
