import { createContext, useState } from 'react'
import { claimDetails, initialClaimDetails, validateClaimDetails, setClaimDetailsInput, isValidClaimNumber, isMandatory, isValidEmail} from './ClaimsContext'
import { reason, setReasonText, setReasonTags, validateReason, initialReason } from './ReasonContext'
import { policyDetails, initialPolicyDetails, validatePolicyDetails, setPolicyDetailsInput, isValidPolicyNumber } from './PolicyContext'
import { quoteDetails, initialQuoteDetails, validateQuoteDetails, setQuoteDetailsInput, isValidCustomerFirst, isValidCustomerLast, validCustomerSelect, isMandatorySelect, isValidTelephone, isValidCustomerEmail} from './QuoteContext'
import { safecoPolicyDetails, initialSafecoPolicyDetails, validateSafecoPolicyDetails, setSafecoPolicyDetailsInput, isValidSafecoPolicyNumber } from './SafecoPolicyContext'
import { blPolicyDetails, initialBLPolicyDetails, validateBLPolicyDetails, setBLPolicyDetailsInput, isValidBLPolicyExpYear, isValidBLPolicyNumber } from './BLPolicyContext'
import {
  setMaxFileSizeError,
  setMaxFileSizeErrorIsCleared,
  setRemovedFiles,
  setUploadedFiles,
  validateUploads,
  uploads,
  initialUploads,
  setDropdownValues,
  setCommentValues,
} from './UploadsContext'
import { ERROR_LIST, SUBMIT_MODAL } from '../constants'
import { postIngestionData } from '../postIngestionData'
import { logEnteredStep, logSubmitFailed, logSubmitOk, logSubmitStarted, logValidateFailed } from '../logger'
import cuid from 'cuid'

export const FormContext = createContext()

export const FormProvider = ({ children }) => {
  const [values, setValues] = useState({
    uploadReason: '',
    activeStep: 0,
    showSubmitModal: false,
    submitProgress: '',
    transactionId: cuid(),
    claimDetails,
    policyDetails,
    quoteDetails,
    safecoPolicyDetails,
    blPolicyDetails,
    reason,
    uploads,
    completedStep : -1,
    completedStepList : [false, false, false],
    enableContinueInfoPage : false,
    enableReviewPage : false,
    invalidFileCombination : false,
  })

  // Changes the corresponding field based on the given stateType
  const handleSetValues = (stateType, event) => {
    switch (stateType) {
    case 'activeStep':
      setActiveStep(event)
      break
    case 'claims':
      setClaimDetailsInput(event, values, setValues)
      break
    case 'policy':
      setPolicyDetailsInput(event, values, setValues)
      break
    case 'quote':
      setQuoteDetailsInput(event, values, setValues)
      break
    case 'safecoPolicy':
      setSafecoPolicyDetailsInput(event, values, setValues)
      break
    case 'blPolicy':
      setBLPolicyDetailsInput(event, values, setValues)
      break
    case 'filesAdded':
      setUploadedFiles(event, values, setValues)
      break
    case 'filesRemoved':
      setRemovedFiles(event, values, setValues)
      break
    case 'setMaxFileSizeError':
      setMaxFileSizeError(event, values, setValues)
      break
    case 'tabNavigation':
      setStepsTabNavigation(event)
      break
    case 'dropdownAdded':
      setDropdownValues(event, values, setValues)
      break
    case 'commentAdded':
      setCommentValues(event, values, setValues)
      break
    case 'DisableReviewStep':
      setDisableReview(event, values, setValues)
      break
    case 'resetContextValues':
      setClearFields(event, values, setValues)
      break
    case 'botInput':
      setBotInput(event, values, setValues)
      break;
    default:
      break
    }
  }

  const setBotInput = (event, values, setValues) => {
    values.botInput = event.target.value !== null && event.target.value !== ''
  }

  // Clears fields to blank and resets values
  const setClearFields = (event, values, setValues) => {
    values.completedStep = -1
    values.completedStepList = [false, false, false],
    values.claimDetails = 
      { 
        claimNumber: {
          value: '',
          error: false,
          valid: (value) => isValidClaimNumber(value)
        },
        contactName: {
          value: '',
          error: false,
          valid: (value) => isMandatory(value)
        },
        contactEmail: {
          value: '',
          error: false,
          valid: (value) => isValidEmail(value)
        },
        companyName: {
          value: '',
          error: false,
          valid: (value) => true
        }
      }
    values.quoteDetails =
    {
      lineOfBusiness: {
        value: '',
        error: false,
        valid: (value) => isMandatorySelect(value)
      },
      programCode: {
        value: '',
        error: false,
        valid: (value) => isMandatorySelect(value)
      },
      state: {
        value: '',
        error: false,
        valid: (value) => isMandatorySelect(value)
      },
      contactFirst: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactLast: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      phoneNumber: {
        value: '',
        error: false,
        valid: (value) => isValidTelephone(value)
      },
      contactEmail: {
        value: '',
        error: false,
        valid: (value) => isValidEmail(value)
      },
      isCustomer: {
        value: '',
        error: false,
        valid: (value) => validCustomerSelect(value)
      },
      customerFirst: {
        value: '',
        error: false,
        valid: (value) => isValidCustomerFirst(values)
      },
      customerLast: {
        value: '',
        error: false,
        valid: (value) => isValidCustomerLast(values)
      },
      customerEmail: {
        value: '',
        error: false,
        valid: (value) => isValidCustomerEmail(values)
      },
    }
    values.policyDetails = {
      policyNumber: {
        value: '',
        error: false,
        valid: (value) => isValidPolicyNumber(value)
      },
      programCode : {
        value: '',
        error: false,
        valid: (value) => true
      },
      state: {
        value: '',
        error: false,
        valid: (value) => isMandatorySelect(value)
      },
      contactName: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactEmail: {
        value: '',
        error: false,
        valid: (value) => isValidEmail(value)
      }
    }
    values.safecoPolicyDetails = {
      safecoPolicyNumber: {
        value: '',
        error: false,
        valid: (value) => isValidSafecoPolicyNumber(value)
      },
      programCode : {
        value: '',
        error: false,
        valid: (value) => true
      },
      state: {
        value: '',
        error: false,
        valid: (value) => isMandatorySelect(value)
      },
      contactFirst: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactLast: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactEmail: {
        value: '',
        error: false,
        valid: (value) => isValidEmail(value)
      },
      isCustomer: {
        value: '',
        error: false,
        valid: (value) => validCustomerSelect(value)
      },
      customerFirst: {
        value: '',
        error: false,
        valid: (value) => isValidCustomerFirst(value)
      },
      customerLast: {
        value: '',
        error: false,
        valid: (value) => isValidCustomerLast(value)
      },
    }
    values.blPolicyDetails = {
      blPolicyNumber: {
        value: '',
        error: false,
        valid: (value) => isValidBLPolicyNumber(value)
      },
      blPolicyExpYear: {
        value: '',
        error: false,
        valid: (value) => isValidBLPolicyExpYear(value)
      },
      contactFirst: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactLast: {
        value: '',
        error: false,
        valid: (value) => isMandatory(value)
      },
      contactEmail: {
        value: '',
        error: false,
        valid: (value) => isValidEmail(value)
      },
    }
    values.uploads = {
      files: [],
      totalUploadSize: 0,
      maxFileSizeError: false,
      maxFileSizeNames: new Set()
    }

    values.enableContinueInfoPage = false
  }

  // Based on the button clicked, navigates to its respective page
  // Back: goes to previous page, Continue/Review: goes to next page
  // Submit: posts the data
  const setActiveStep = (event) => {
    let newStep = values.activeStep
    let hasClickedSubmit = false
    let compStep = values.completedStep
    let compStepList = values.completedStepList
    let enableContinueInfoPage = values.enableContinueInfoPage

    switch (true) {
    case event.target.innerText === 'Continue' && values.activeStep === 0:
      if (!values.botInput) {
        if (validateClaimDetails(values, setValues) || validatePolicyDetails(values, setValues)
            || validateQuoteDetails(values, setValues) || validateSafecoPolicyDetails(values, setValues) || validateBLPolicyDetails(values, setValues)) {
          newStep = values.activeStep + 1
          compStep = values.activeStep
          compStepList = [true, false, false]
          logEnteredStep(values)
          break
        }
      } else {
        enableContinueInfoPage = false
        break
      }
      // logValidateFailed(values)
      break
    case event.target.innerText === 'Review' && values.activeStep === 1:
      newStep = values.activeStep + 1
      compStep = values.activeStep
      compStepList = [true, true, true]
      logEnteredStep(values)
      break

    case event.target.innerText === 'Upload files' && values.activeStep === 2:
      newStep = values.activeStep + 1
      compStep = values.activeStep
      compStepList = [true, true, true]
      break
    case event.target.innerText === 'Continue' && values.activeStep === 2:
      if (validateUploads(values, setValues)) {
        newStep = values.activeStep + 1
        compStep = values.activeStep
        compStepList = [true, true, true]
        // logEnteredStep(values)
        break
      }
      // logValidateFailed(values)
      break
    case event.target.innerText.includes('Back'):
      newStep = values.activeStep - 1
      compStep = newStep - 1
      // logEnteredStep(values)
      break
    case event.target.innerText === 'Submit':
      hasClickedSubmit = true
      doPostData()
      logSubmitStarted(values)
      break
    default:
      newStep = values.activeStep
      break
    }
    setValues((prevValues) => ({
      ...prevValues,
      activeStep: newStep,
      showSubmitModal: hasClickedSubmit,
      completedStep: compStep,
      completedStepList: compStepList
    }))
  }

  // Stepper that shows the user which 'step' they are on. The possible steps for the user are : Details, File Selection, and Review
  // User is only able to progress through steps if information and values are valid entries
  const setStepsTabNavigation = (gotoStep) => {
    let newStep = values.activeStep
    const hasClickedSubmit = false

    const claimAndReviewComp = validateClaimDetails(values, setValues) && values.enableReviewPage
    const policyAndReviewComp = validatePolicyDetails(values, setValues) && values.enableReviewPage
    const quoteAndReviewComp = validateQuoteDetails(values, setValues) && values.enableReviewPage
    const safecoPolicyAndReviewComp = validateSafecoPolicyDetails(values, setValues) && values.enableReviewPage

    // forward-navigation
    if (gotoStep > values.activeStep) {
      switch (true) {
      case values.activeStep === 0 && gotoStep === 1:
        if (values.completedStep === 1 || values.uploads.files.length > 0) {
          if (validateClaimDetails(values, setValues) || validatePolicyDetails(values, setValues)
            || validateQuoteDetails(values, setValues) || validateSafecoPolicyDetails(values, setValues) || validateBLPolicyDetails(values, setValues)) {
            newStep = gotoStep
            // logEnteredStep(values)
            break
          }
        }  
        break

      case values.activeStep === 0 && gotoStep === 2:
        if (claimAndReviewComp || policyAndReviewComp || quoteAndReviewComp || safecoPolicyAndReviewComp) {
          if (values.completedStep === 1 || values.uploads.files.length > 0) {
            newStep = gotoStep
            // logEnteredStep(values)
            break
          }  
          values.completedStepList = [true, false, false]
        }  
        break

      case values.activeStep === 1 && gotoStep === 2:
        if (claimAndReviewComp || policyAndReviewComp || quoteAndReviewComp || safecoPolicyAndReviewComp) {
          if (values.completedStep === 1 || values.uploads.files.length > 0) {
            newStep = gotoStep
            // logEnteredStep(values)
            break
          }  
          values.completedStepList = [true, true, false]
        }
        break
      
      default:
        break
      }
    }

    // backward-navigation
    if (gotoStep < values.activeStep) {
      switch (true) {
      case values.activeStep === 1 && gotoStep === 0:
        newStep = values.activeStep - 1
        break
      case values.activeStep === 2 && gotoStep === 1:
        newStep = values.activeStep - 1
        break
      case values.activeStep === 2 && gotoStep === 0:
        newStep = values.activeStep - 2
        break
      default:
        break
      }
    }

    setValues((prevValues) => ({
      ...prevValues,
      activeStep: newStep,
      showSubmitModal: hasClickedSubmit
    }))
  }

  // Sets corresponding error message for details based on given stateType
  const setErrorMsg = (stateType, errorTypes) => {
    const errorMessages = []
    let error = false
    for (const errorType of errorTypes) {
      switch (stateType) {
      case 'claims':
        error = values.claimDetails[errorType].error
        break
      case 'policy':
        error = values.policyDetails[errorType].error
        break
      case 'quote':
        error = values.quoteDetails[errorType].error  
        break  
      case 'safecoPolicy':
        error = values.safecoPolicyDetails[errorType].error
        break
      case 'blPolicy':
        error = values.blPolicyDetails[errorType].error
        break
      case 'reason':
        error = values.reason.freeTextError
        break
      case 'uploads':
        break
      default:
        break
      }
      if (error) {
        const errorMsg = ERROR_LIST[errorType]
        errorMessages.push({
          text: errorMsg,
          type: 'error'
        })
      }
    }
    if (errorMessages.length > 0) {
      return errorMessages
    } else {
      return undefined
    }
  }

  // Appends index and category to each file submitted
  const prepareFileNames = () => {
    values.uploads.files.forEach((element, idx) => {
      element.rawFile.name = `${idx}_${element.rawFile.category}_${element.rawFile.name}`
    })
    setValues((prevValues) => ({
      ...prevValues,
      values
    }))
  }

  // Sets submitProgress based on given progress string
  const setSubmitProgress = (progress) => {
    switch (progress) {
    case 'submitting':
      values.submitProgress = 'submitting'
      break
    case 'success':
      values.submitProgress = 'success'
      break
    case 'error':
    default:
      values.submitProgress = 'error'
      break
    }
    setValues((prevValues) => ({
      ...prevValues,
      submitProgress: values.submitProgress
    }))
  }

  // Posts the data and uploads submitProgress
  const doPostData = () => {
    prepareFileNames()
    setSubmitProgress('submitting')
    postIngestionData(values)
      .then(() => {
        setSubmitProgress('success')
        logSubmitOk(values)
      })
      .catch((error) => {
        setSubmitProgress('error')
        logSubmitFailed(values, error)
      })
  }
  
  // Disables going to the review page
  const setDisableReview = (event, values, setValues) => {
    setValues((prevValues) => ({
      ...prevValues,
      completedStepList: [true,true,false]
    }))
  }
  
  return (
    <FormContext.Provider value={[values, handleSetValues, setErrorMsg]}>
      {children}
    </FormContext.Provider>
  )
}
