import { React, useContext } from 'react'
import {
  Heading,
  GridRow,
  GridCol,
} from '@lmig/lmds-react'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import { Box } from '@mui/material'
import { FormContext } from '../../FormContext/FormContext'

// Renders the overview section of the review page for quote in progress
// Shows line of business, state, uploader name, uploader email, is they are the customer and customer name (if they are not the customer)
const SafecoPolicyOverview = () => {
  const [values] = useContext(FormContext)
  const isMobile = useCheckIsMobile()
  return (
    <Box>
      <Heading type='h5-bold' style={{ marginBottom: '0px' }}>Overview</Heading>
      <hr style={{ border: '1px solid black' }} />
      <GridRow direction="column" gutterSize='default' gutters='horizontal'>
        {isMobile ?
          <>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ wordWrap: 'break-word' }}><strong>Policy number</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ wordBreak: 'break-word', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.safecoPolicyNumber.value}`}</span>
              </GridCol>
            </GridRow>
            {values.safecoPolicyDetails.programCode.value ? <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '0px' }}>Program</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.programCode.value}`}</span>
              </GridCol>
            </GridRow> : ''}
            {values.safecoPolicyDetails.state.value !== '' ?
              <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
                <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                  <span style={{ wordWrap: 'break-word' }}><strong>State</strong></span>
                </GridCol>
                <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                  <span style={{ wordBreak: 'break-word' }}>{`${values.safecoPolicyDetails.state.value}`}</span>
                </GridCol>  
              </GridRow> : ''}
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ wordWrap: 'break-word' }}><strong>Uploader name</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ wordBreak: 'break-word' }}>{`${values.safecoPolicyDetails.contactFirst.value} ${values.safecoPolicyDetails.contactLast.value}`}</span>
              </GridCol>
            </GridRow>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ wordWrap: 'break-word' }}><strong>Uploader email</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ wordBreak: 'break-word' }}>{`${values.safecoPolicyDetails.contactEmail.value}`}</span>
              </GridCol>
            </GridRow>
            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                <span style={{ wordWrap: 'break-word' }}><strong>Are you the customer?</strong></span>
              </GridCol>
              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                <span style={{ wordBreak: 'break-word', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.isCustomer.value}`}</span>
              </GridCol>
            </GridRow>
            {values.safecoPolicyDetails.isCustomer.value === 'no' &&
                            <GridRow justify='between' style={{ display: 'flex', alignContent: 'space-between', width: '100%', margin: '8px 16px 8px 16px', paddingBottom: '10px', borderBottom: '1px solid #E6E6E6' }}>
                              <GridCol base={6} style={{ textAlign: 'left', float: 'left' }}>
                                <span style={{ wordWrap: 'break-word' }}><strong>Customer name</strong></span>
                              </GridCol>
                              <GridCol base={6} style={{ textAlign: 'end', float: 'right', width: '100%' }}>
                                <span style={{ wordBreak: 'break-word' }}>{`${values.safecoPolicyDetails.customerFirst.value} ${values.safecoPolicyDetails.customerLast.value}`}</span>
                              </GridCol>
                            </GridRow>
            }
          </>
          :
          <>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Policy number</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.safecoPolicyNumber.value}`}</span>
              </div>
            </GridCol>
            {values.safecoPolicyDetails.programCode.value ? <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Program</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.programCode.value}`}</span>
              </div>
            </GridCol> : ''}
            {values.safecoPolicyDetails.state.value !== '' ?
              <GridCol base={6} justify='between'>
                <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                  <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>State</strong></span>
                  <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.safecoPolicyDetails.state.value}`}</span>
                </div> 
                <div></div>
              </GridCol> : ''
            }
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Uploader name</strong></span>
                <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.safecoPolicyDetails.contactFirst.value} ${values.safecoPolicyDetails.contactLast.value}`}</span>
              </div>
            </GridCol>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Uploader email</strong></span>
                <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.safecoPolicyDetails.contactEmail.value}`}</span>
              </div>
            </GridCol>
            <GridCol base={6} justify='between'>
              <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Are you the customer?</strong></span>
                <span style={{ float: 'right', paddingRight: '15px', textTransform: 'capitalize' }}>{`${values.safecoPolicyDetails.isCustomer.value}`}</span>
              </div>
            </GridCol>
            {values.safecoPolicyDetails.isCustomer.value === 'no' &&
              <GridCol base={6} justify='between'>
                <div style={{ borderBottom: '1px solid #E6E6E6', margin: '10px 0px 10px 0px', paddingBottom: '10px', overflow: 'hidden' }}>
                  <span style={{ float: 'left' }}><strong style={{ paddingLeft: '15px' }}>Customer name</strong></span>
                  <span style={{ float: 'right', paddingRight: '15px' }}>{`${values.safecoPolicyDetails.customerFirst.value} ${values.safecoPolicyDetails.customerLast.value}`}</span>
                </div>
              </GridCol>
            }
          </>
        }
      </GridRow>
    </Box>
  )
}

export default SafecoPolicyOverview
