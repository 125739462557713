import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { BodyText, Button, Heading } from '@lmig/lmds-react'

// Component for Drag and Drop. Also has a button inside for uploading files instead of drag and dropping.

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  margin: '32px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#F5F5F5',
  borderStyle: 'dotted',
  color: '#C0BFC0',
  height: '15rem',
  width: '100%',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#F5F5F5'
}

const Dropzone = ({ onDrop, dropzoneIcon, fileText, dropzoneText }) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, noClick: true })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <Box className='container' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Box {...getRootProps({ style })}>
        {dropzoneIcon}
        <input {...getInputProps()} />
        {isDragReject ? (
          <></>
        ) : (
          <Heading type='h4-light' style={{ textAlign: 'center' }}>
            {dropzoneText}
          </Heading>
        )}
        <Button align="center"
          isLargeOnMobile
          type='submit'
          variant='secondary'
          style={{ width: '-webkit-fill-available', marginBottom: '32px' }}
          onClick={open}
        >
          {fileText}
        </Button>
      </Box>
    </Box>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func
}

export default Dropzone
