const getEnvFromHostname = () => {
  const { hostname } = global.window.location

  const env = {
    API: 'http://localhost:8080',
    HOST: 'production',
    PORT: 443,
    SSL: true,
    ENV: 'production'
  }
  
  // production : internal
  if (hostname === 'ds-digital-ingestion-secure-upload-portal.us-east-1.paas.lmig.com') {
    env.API = 'https://ds-digital-ingestion-secure-upload-portal.us-east-1.paas.lmig.com'
    env.HOST = 'ds-digital-ingestion-secure-upload-portal.us-east-1.paas.lmig.com'
    env.ENV = 'production'
  }

  // production : internal_vanity
  if (hostname === 'secureupload.lmig.com') {
    env.API = 'https://secureupload.lmig.com'
    env.HOST = 'secureupload.lmig.com'
    env.ENV = 'production'
  }

  // production : external
  if (hostname === 'secureupload.libertymutual.com') {
    env.API = 'https://secureupload.libertymutual.com'
    env.HOST = 'secureupload.libertymutual.com'
    env.ENV = 'production'
  }

  // test : internal
  if (hostname === 'ds-digital-ingestion-secure-upload-portal-test.us-east-1.np.paas.lmig.com') {
    env.API = 'https://ds-digital-ingestion-secure-upload-portal-test.us-east-1.np.paas.lmig.com'
    env.HOST = 'ds-digital-ingestion-secure-upload-portal-test.us-east-1.np.paas.lmig.com'
    env.ENV = 'test'
  }

  // test : internal_vanity
  if (hostname === 'test-secureupload.lmig.com') {
    env.API = 'https://test-secureupload.lmig.com'
    env.HOST = 'test-secureupload.lmig.com'
    env.ENV = 'test'
  } 

  // test : external
  if (hostname === 'test-secureupload.libertymutual.com') {
    env.API = 'https://test-secureupload.libertymutual.com'
    env.HOST = 'test-secureupload.libertymutual.com'
    env.ENV = 'test'
  }


  // development : internal
  if (hostname === 'ds-digital-ingestion-secure-upload-portal-development.us-east-1.np.paas.lmig.com') {
    env.API = 'https://ds-digital-ingestion-secure-upload-portal-development.us-east-1.np.paas.lmig.com'
    env.HOST = 'ds-digital-ingestion-secure-upload-portal-development.us-east-1.np.paas.lmig.com'
    env.ENV = 'development'
  }

  // development : internal_vanity
  if (hostname === 'dev-secureupload.lmig.com') {
    env.API = 'https://dev-secureupload.lmig.com'
    env.HOST = 'dev-secureupload.lmig.com'
    env.ENV = 'development'
  }


  if (hostname.startsWith('localhost')) {
    env.API = 'http://localhost:8080'
    env.HOST = 'localhost'
    env.ENV = 'development'
    env.PORT = 8080
    env.SSL = false
  }

  if (hostname.startsWith('127.0.0.1')) {
    env.API = 'http://127.0.0.1:8080'
    env.HOST = '127.0.0.1'
    env.ENV = 'development'
    env.PORT = 8080
    env.SSL = false
  }

  return env
}

const getEnv = (property) => getEnvFromHostname()[property]

export default getEnv
