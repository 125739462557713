import React from 'react'
import FileDataView from './FileDataView'
import { Heading, GridRow, GridCol } from '@lmig/lmds-react'
import { Button } from '@lmig/lmds-react-button'
import './FileDataView.css'
import { FILVIEW_ABOUT_FILES_TEXT, FILVIEW_ABOUT_FILES_SUBTEXT } from '../../constants'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'
import ChooseAnotherFileText from '../../Components/ChooseAnotherFileText'

// Renders file view on the File Selection page for desktop and mobile when a file is added 
const ViewBox = (props) => 
  (
    <React.Fragment>
      <GridRow data-testid='view-box'>
        <GridCol>
          {useCheckIsMobile() ?
            <Heading type='h4' align='left' className='fileview-heading' style={{ maxWidth: 'inherit', marginBottom: '8px' }} >
              {FILVIEW_ABOUT_FILES_TEXT}
            </Heading>
            :
            <Heading type='h4' align='left' className='fileview-heading' style={{ maxWidth: 'inherit', marginLeft: '17px' }} >
              {FILVIEW_ABOUT_FILES_TEXT}
            </Heading>}
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          {useCheckIsMobile() ?
            <Heading type='h1' align='left' className='fileview-subheading' style={{ maxWidth: 'inherit', margin:'0px', padding:'0px' }} >
              {FILVIEW_ABOUT_FILES_SUBTEXT}
            </Heading>
            :
            <Heading type='h1' align='left' className='fileview-subheading' style={{ maxWidth: 'inherit', marginLeft: '20px', marginBottom: '35px', paddingRight: '10px' }} >
              {FILVIEW_ABOUT_FILES_SUBTEXT}
            </Heading>
          }
        </GridCol>
      </GridRow>

      {props.files.map((file, index) => <FileDataView key={index} file={file} index={index} />)}

      {useCheckIsMobile() ? <hr style={{ marginTop: '22px' }} /> : <hr style={{ marginTop: '22px', marginLeft: '21px', marginRight: '21px' }} />}

      <ChooseAnotherFileText onDrop={props.onDrop} />
      {useCheckIsMobile() ? <Button id='reviewButton' disabled={!props.values.enableReviewPage} onClick={props.checkFileUploads} isLargeOnMobile size="small" variant="primary" style={{ padding: '9px 16px 8px', width: '-webkit-fill-available' }} >Review</Button> :
        <Button id='reviewButton' disabled={!props.values.enableReviewPage} onClick={props.checkFileUploads} isLargeOnMobile size="small" variant="primary" style={{ padding: '9px 16px 8px', width: '175px', marginLeft: '21px' }} >Review</Button>}
    </React.Fragment>
  )

export default ViewBox
