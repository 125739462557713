import React from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Button } from '@lmig/lmds-react'

// Mobile version of the 'Choose file' and 'Choose another file' button in the DnD box. 
// Please note that DnD box does not exist for mobile view.
const ChooseFileButton = ({ onDrop, fileText }) => {
  const {
    getInputProps,
    open
  } = useDropzone({ onDrop, noClick: true, noDrag: true })

  return (
    <Box style={{ width: '100%' }}>
      <input {...getInputProps()} />
      <Button align="center"
        isLargeOnMobile
        type='submit'
        id='choose-file'
        variant='secondary'
        style={{ width: '100%', marginTop: '16px', marginBottom: '32px', textAlign: 'center' }}
        onClick={open}
      >
        {fileText}
      </Button>
    </Box>
  )
}

ChooseFileButton.propTypes = {
  onDrop: PropTypes.func
}

export default ChooseFileButton
