import React from 'react'
import { GridRow, Heading } from '@lmig/lmds-react'
import FileIncludedView from './FileIncludedView'
import { useCheckIsMobile } from '../../Hooks/UseCheckIsMobile'

// Renders the file view on the Review screen
const FileIncludedSection = (props) => (
  <React.Fragment>
    <Heading data-testid='file-section' type="h5-bold">Files Included</Heading>
    <hr style={{ border: '1px solid black' }} />
    <GridRow >
      {props.files.map((file, index) => <FileIncludedView key={index} file={file} index={index} />)}
    </GridRow>
    {useCheckIsMobile() ? '' : <hr style={{ border: '1px solid #E6E6E6' }} />}
  </React.Fragment>
)
export default FileIncludedSection
