export const getDoctypeInternalSafecoPolicy = (category, subcategory, lob) => {
  let docDescription = '';
  let docName = '';
  let retentionClass = '';
  let manualIndexing = '';
  let notification = '';
  let workflowMailbox = '';

  // Mapping category to subcategory for Safeco Policy
  // Homeowner
  if (category === 'Home Coverage') {
    switch (true) {
    case subcategory === 'Daycare Questionnaire':
      docDescription = 'Daycare Questionnaire';
      docName = 'DCQ';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'MVRLIC@safeco.com';
      break;
    case subcategory === 'Earthquake':
      docDescription = 'Earthquake Offer/Acceptance';
      docName = 'EQ';  
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Mine Subsidence': 
      docDescription = 'Mine Subsidence';
      docName = 'MINE';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Personal Property Schedule':
      docDescription = 'SPP-Scheduled Personal Property';
      docName = 'MISC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Property Insurance Disclosure - CA (Petris)':
      docDescription = 'Petris Disclosure Form';
      docName = 'PD';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Mold- TX':
      docDescription = 'Texas Mold';
      docName = 'Texas Mold';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Wildfire Opt In':
      docDescription = 'Wild Fire Opt In';
      docName = 'WFOI';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Wildfire Opt out':
      docDescription = 'Wildfire Opt Out';
      docName = 'WFOO';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Other':
      docDescription = 'Application';
      docName = 'APP';
      retentionClass = 'UND196';
      manualIndexing = 'Yes';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;
    }
  }

  if (category === 'Home Discount') {
    switch (true) {
    case subcategory === 'Highly Skilled and Educated Worker - CA':
      docDescription = 'Verification';
      docName = 'VERI';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Roof':
      docDescription = 'Roofing Info';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UABXVA@safeco.com';
      break;
    case subcategory === 'Security System Certificate':
      docDescription = 'Security System Certificate';
      docName = 'ALM';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UABXVA@safeco.com';
      break;
    case subcategory === 'Sprinkler System':
      docDescription = 'Sprinkler System';
      docName = 'SS';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Other':
      docDescription = 'Application';
      docName = 'APP';
      retentionClass = 'UND196';
      manualIndexing = 'Yes';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;
    }
  }

  if (category === 'Policy Request' && lob === 'homeowner') {
    switch (true) {
    case subcategory === 'Endorsement':
      docDescription = 'Endorsement Request';
      docName = 'END';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'HOMEND@safeco.com';
      break;
    case subcategory === 'Policy Cancel':
      docDescription = 'Cancellation Request';
      docName = 'CANC';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PACCAN@safeco.com';
      break;
    case subcategory === 'Reinstatement':
      docDescription = 'Request for Reinstate';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;
    case subcategory === 'Rescore':
      docDescription = 'Request to rerun for IBS or Credit';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PROPRE@safeco.com';
      break;
    }
  }

  if (category === 'Verification/Documentation') {
    switch (true) {
    case subcategory === 'Agent (AOR)':
      docDescription = 'Agent of Record';
      docName = 'AOR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Agent Copy of Cancel Notice':
      docDescription = 'Agent Copy of Cancel Notice';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Appraisal':
      docDescription = 'Appraisal';
      docName = 'APRSL';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'HOMEND@safeco.com';
      break;
    case subcategory === 'Bill Breakdown':
      docDescription = 'Bill Breakdown';
      docName = 'BRB';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'CA Fair Plan':
      docDescription = 'CA Fair Plan';
      docName = 'FAIR';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Electronic Funds Authorization':
      docDescription = 'Checkless Auth Vdck';
      docName = 'CBP';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'ATLFAX@safeco.com';
      break;
    case subcategory === 'Foreclosure Notice':
      docDescription = 'Foreclosure';
      docName = 'FOR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Hail Resistant Roof':
      docDescription = 'Hail Resistant Roof';
      docName = 'HRR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'UABXVA@LibertyMutual.com'
      break;
    case subcategory === 'Home Discount Questionnaire':
      docDescription = 'Home Discount Questionnaire';
      docName = 'HMDSCTQSTNR';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'SFDISQ@LibertyMutual.com'
      break;
    case subcategory === 'Home Evaluation':
      docDescription = 'Property Survey Form';
      docName = 'PSF';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Home Inspection':
      docDescription = 'Home Inspection';
      docName = 'HI';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Letter of Experience (LOE)':
      docDescription = 'Experience Letters';
      docName = 'EXPLTR';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Limited Property Form - CA':
      docDescription = 'CA Limited Prop Form';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Mine Subsidence':
      docDescription = 'Mine Subsidence';
      docName = 'MINE';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'newbusinessforms@safeco.com';
      break;
    case subcategory === 'Mortgagee Letter':
      docDescription = 'Mortgagee Letter';
      docName = 'MTG';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PLOMTG@safeco.com';
      break;
    case subcategory === 'Mortgagee List':
      docDescription = 'Mortgagee List';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'TMSMortgageChangeList@safeco.com';
      break;
    case subcategory === 'NY Anti-Arson':
      docDescription = 'NY Anti-Arson';
      docName = 'NYAA';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'nyaafv@safeco.com';
      break;
    case subcategory === 'Photo':
      docDescription = 'Photo';
      docName = 'PHOTO';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Plumbing':
      docDescription = 'Plumbing Verification';
      docName = 'MISC';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'SFPlumbPEP@safeco.com';
      break;
    case subcategory === 'Power of Attorney':
      docDescription = 'Power of Attorney';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Price Validation Form':
      docDescription = 'Price Validation Form';
      docName = 'PVF';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'PVFSAF@safeco.com'
      break;
    case subcategory === 'Proof of Prior Carrier Insurance':
      docDescription = 'Prior Carrier Cancel Notification';
      docName = 'MISC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Proof of Insurance':
      docDescription = 'Binders';
      docName = 'BIND';
      retentionClass = 'POL180';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'MTBIND@safeco.com';
      break;
    case subcategory === 'Protection Class Verification':
      docDescription = 'Protection Class Verification';
      docName = 'PC';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Returned Renewal Questionnaire':
      docDescription = 'Property Returned RQ';
      docName = 'PRRQ';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'SafecoPropertyRQ@safeco.com';
      break;
    case subcategory === 'Roof Age':
      docDescription = 'Roof Age';
      docName = 'ROOF';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'SFRoofPEP@safeco.com';
      break;
    case subcategory === 'Roof Material':
      docDescription = 'Roofing Info';
      docName = 'CORR';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Statement of No Loss':
      docDescription = 'Statement of No Loss';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    case subcategory === 'Wildfire Mitigation Document':
      docDescription === 'Wildfire Mitigation Document';
      docName = 'WLDFRMIT';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'POLDEF@libertymutual.com';
      break;
    case subcategory === 'Wind Hail Exclusion':
      docDescription = 'Wind Hail Exclusion';
      docName = 'WHE';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'PHOTFU@safeco.com';
      break;
    case subcategory === 'Wind Hail Rejection':
      docDescription = 'Wind Hail Rejection';
      docName = 'WHR';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'PHOTFU@safeco.com';
      break;
    case subcategory === 'Other':
      docDescription = 'General Correspondence';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    }
  }



  // Auto
  if (category === 'Auto Coverage') {
    switch (true) {
    case subcategory === 'Additional Driver Discovery':
      docDescription = 'ADD Letter';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Bodily Injury - MI':
      docDescription = 'MI BI Coverage';
      docName = 'MIBI';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'misignatureforms@safeco.com';
      break;

    case subcategory === 'Driver Exclusion':
      docDescription = 'Exclusion';
      docName = 'EXCL';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'EXCLSN@safeco.com';
      break;

    case subcategory === 'FL Uninsured Motorist':
      docDescription = 'Florida Uninsured Motorist';
      docName = 'FLUM';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UMFORM@safeco.com';
      break;

    case subcategory === 'PA Uninsured Motorist':
      docDescription = 'PA UIM Motorist - Complete';
      docName = 'PAUM';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UMFORM@safeco.com';
      break;

    case subcategory === 'Personal Injury Protection - MI':
      docDescription = 'MI PIP Coverage';
      docName = 'MIPIP';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'misignatureforms@safeco.com';
      break;

    case subcategory === 'Personal Injury Protection':
      docDescription = 'MI PIP';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Qualified Health Coverage Document - MI':
      docDescription = 'QHC Verification';
      docName = 'MIQHC';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'misignatureforms@safeco.com';
      break;

    case subcategory === 'Tort - PA':
      docDescription = 'PA Tort - Complete';
      docName = 'PATOR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Uninsured Motorist':
      docDescription = 'Uninsured Motorist Form';
      docName = 'UM';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UMFORM@safeco.com';
      break;

    case subcategory === 'Uninsured Motorist Bodily Injury':
      docDescription = 'UMBI Form';
      docName = 'UM';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UMFORM@safeco.com';
      break;
    case subcategory === 'Other':
      docDescription = 'Application';
      docName = 'APP';
      retentionClass = 'UND196';
      manualIndexing = 'Yes';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;
    }
  }

  if (category === 'Auto Discount') {
    switch (true) {
    case subcategory === 'Accident Prevention Certificate':
      docDescription = 'Accident Prevention Certificate';
      docName = 'APC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UABXVA@safeco.com';
      break;

    case subcategory === 'Distant Student':
      docDescription = 'Distant Student';
      docName = 'DS';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Driving Training Certificate':
      docDescription = 'Driver Training Certificate';
      docName = 'DTC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UABXVA@safeco.com';
      break;

    case subcategory === 'Good Student Grade Report':
      docDescription = 'Good Student Discount';
      docName = 'GSD';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'GSDUPD@safeco.com';
      break;

    case subcategory === 'Highly Skilled and Educated Worker - CA':
      docDescription = 'Verification';
      docName = 'VERI';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Low Mileage':
      docDescription = 'Low Mileage Discount';
      docName = 'LWM';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Military Application':
      docDescription = 'Military Application';
      docName = 'MILAPP';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Young Driver Questionaire':
      docDescription = 'Young Driver Questionaire';
      docName = 'YDQ';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'UABXVA@safeco.com';
      break;

    case subcategory === 'Other':
      docDescription = 'Application';
      docName = 'APP';
      retentionClass = 'UND196';
      manualIndexing = 'Yes';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;
    }
  }

  if (category === 'Policy Request' && lob === 'auto') {
    switch (true) {
    case subcategory === 'Endorsement':
      docDescription = 'Endorsement Request';
      docName = 'END';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'AUTEND@safeco.com';
      break;

    case subcategory === 'Policy Cancel':
      docDescription = 'Cancellation Request';
      docName = 'CANC';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PACCAN@safeco.com';
      break;

    case subcategory === 'Reinstatement':
      docDescription = 'Request for Reinstate';
      docName = 'CORR';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'documents@safeco.com';
      break;

    case subcategory === 'Rescore':
      docDescription = 'Request to rerun for IBS or Credit';
      docName = 'CORR';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PROPRE@safeco.com';
      break;
    }
  }

  if (category === 'Documentation') {
    switch (true) {
    case subcategory === 'Agent (AOR)':
      docDescription = 'Agent of Record';
      docName = 'AOR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'PLOAOR@safeco.com';
      break;

    case subcategory === 'Agent Copy of Cancel Notice':
      docDescription = 'Agent Copy of Cancel Notice';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Bill Breakdown':
      docDescription = 'Bill Breakdown';
      docName = 'BRB';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'DMV Vehicle/Driver\'s License Verification':
      docDescription = 'Vehicle Reg/Title';
      docName = 'VEHREG';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'DMV@safeco.com';
      break;

    case subcategory === 'Driver MVR':
      docDescription = 'MVR for Driver';
      docName = 'MVR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'MVRLIC@safeco.com';
      break;

    case subcategory === 'Drivers License':
      docDescription = 'Drivers License';
      docName = 'DL';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'MVRLIC@safeco.com';
      break;

    case subcategory === 'Electronic Funds Authorization':
      docDescription = 'Checkless Auth Vdck';
      docName = 'CBP';
      retentionClass = 'ACC110';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'ATLFAX@safeco.com';
      break;

    case subcategory === 'Inspection Report - NY':
      docDescription = 'NY INSP Report';
      docName = 'MISC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'SAFCAR@safeco.com';
      break;

    case subcategory === 'Lease Agreement':
      docDescription = 'Lease Agreement';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Letter of Experience (LOE)':
      docDescription = 'Experience Letters';
      docName = 'EXPLTR';
      retentionClass = 'POL100';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Photo':
      docDescription = 'Photo';
      docName = 'PHOTO';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Plate Return Receipt - NY':
      docDescription = 'NYFS6';
      docName = 'MISC';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = 'AUTEND@safeco.com';
      break;

    case subcategory === 'Power of Attorney':
      docDescription = 'Power of Attorney';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    
    case subcategory === 'Price Validation Form':
      docDescription = 'Price Validation Form';
      docName = 'PVF';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'PVFSAF@safeco.com'
      break;

    case subcategory === 'Proof of Homeownership':
      docDescription = 'Homeownership Verification';
      docName = 'PHO';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Proof of No Fault':
      docDescription = 'Proof of Not At Fault Accident';
      docName = 'NAF';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Proof of Prior Carrier Insurance':
      docDescription = 'Binders';
      docName = 'BIND';
      retentionClass = 'POL180';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Returned Renewal Questionnaire':
      docDescription = 'Auto Returned RQ';
      docName = 'ARRQ';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'SafecoAutoRQ@safeco.com';
      break;

    case subcategory === 'Spousal Liability Form':
      docDescription = 'Spousal Liability Form';
      docName = 'SPSLLBLTY';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'No';
      workflowMailbox = 'NYSSLR@libertymutual.com';
      break;

    case subcategory === 'Statement of No Loss':
      docDescription = 'Statement of No Loss';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Undisclosed Operator Verification':
      docDescription = 'Undisclosed Operator Verification';
      docName = 'UDO';
      retentionClass = 'UND196';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;

    case subcategory === 'Other':
      docDescription = 'General Correspondence';
      docName = 'CORR';
      retentionClass = 'UND195';
      manualIndexing = 'No';
      notification = 'Yes';
      workflowMailbox = '';
      break;
    }
  }

  return { docDescription, docName, retentionClass, manualIndexing, notification, workflowMailbox };
}
